import React from 'react'
import Tooltip from 'components/tooltips/Tooltip'
import Icon from 'components/elements/Icon'
import { Spacer } from 'views_DEPRICATED/Pages/style'

type Props = {}

/**
 * This component displays a hint in the CardModal giving a brief intro to multiple categories.
 */
const CardModalCategoriesInfo: React.FC<Props> = () => {
  return (
    <Tooltip
      content={
        <p>
          You can add multiple categories for a card. Only the first category for a card will be used for legality.
          <Spacer height={10} />
          You can view all categories visually by going under views <Icon name="eye" /> and changing the stack sorting
          <Icon name="clone" /> to "Multiple".
          <Spacer height={10} />
          Additional category controls can be found in the deck settings <Icon name="settings" />
        </p>
      }
    />
  )
}

export default CardModalCategoriesInfo

import { CardType } from 'types/deck'
import { DeckCardInfoType } from 'types/search'
import { ToastType } from 'types/toasts'
import { Tab } from 'types/searchV2'
import { GlobalColorTag } from 'services/apiTypes/colorTag.types'

export type PriceSourceType = Array<number>

export type DeckResultType = {
  results: Array<DeckCardInfoType>
  next: string
  loading: boolean
}

export interface ActiveState {
  card: CardType
  modalActive: boolean
  textActive: boolean
  rightDetailsTab: number
  leftDetailsTab: number
  newDeckModalActive: boolean
  isMobile: boolean
  isMidsize: boolean
  isLarge: boolean
  recentDecks: DeckResultType
  yourDecks: DeckResultType
  recentlyViewed: DeckResultType
  followedDecks: DeckResultType
  deckResults: DeckResultType
  totalCount: number
  decksWithCard: DeckResultType
  noFocus: boolean
  modals: Record<string, any>
  priceSource: PriceSourceType
  highlightedCards: string[]
  keybinds: number
  isDeckPage: boolean
  modalOverlay: {
    type: string | null
    props: any
  }
  toasts: Array<ToastType>
  searchOpen: boolean | 'search' | 'recs' | 'manabaseGenerator' | 'spellbookCombo' | Tab
  rightPanelOpen: boolean | 'stats' | 'buy' | 'probability'
  optimizeCardOverlay: boolean
  idleOverlay: boolean
  multiSelectedIds: Record<string, boolean>
  multiSelectedCollectionIds: Record<string, boolean>

  stackOverlayIds: string[]
  hideStackOverlay: boolean

  globalColorTags: null | GlobalColorTag[]
}

export const SET_ACTIVE_CARD = 'active/SELECT_CARD'
export const TOGGLE_MODAL_ON = 'active/TOGGLE_MODAL_ON'
export const TOGGLE_MODAL_OFF = 'active/TOGGLE_MODAL_OFF'
export const TOGGLE_TEXT_ON = 'active/TOGGLE_TEXT_ON'
export const TOGGLE_TEXT_OFF = 'active/TOGGLE_TEXT_OFF'
export const SET_RIGHT_PANEL_VIEW = 'active/SET_RIGHT_PANEL_VIEW'
export const SET_LEFT_PANEL_VIEW = 'active/SET_LEFT_PANEL_VIEW'
export const SET_DETAILS_TAB = 'active/SET_DETAILS_TAB'
export const SET_MOBILE = 'active/SET_MOBILE'
export const GET_RECENT_DECKS_REQUESTED = 'active/GET_RECENT_DECKS_REQUESTED'
export const GET_RECENT_DECKS_SUCCEEDED = 'active/GET_RECENT_DECKS_SUCCEEDED'
export const GET_RECENT_DECKS_FAILED = 'active/GET_RECENT_DECKS_FAILED'
export const SET_LOADING_DECKS = 'active/SET_LOADING_DECKS'
export const TOGGLE_NO_FOCUS = 'active/TOGGLE_NO_FOCUS'
export const TOGGLE_MODAL = 'active/TOGGLE_MODAL'
export const PAGE_CHANGE = 'active/PAGE_CHANGE'
export const SET_PRICE_SOURCE = 'active/SET_PRICE_SOURCE'
export const LOAD_HOME_WHEELS = 'active/LOAD_HOME_WHEELS'
export const SET_HIGHLIGHT_CARDS = 'active/SET_HIGHLIGHT_CARDS'
export const SET_ACTIVE_PREFERENCES = 'active/SET_ACTIVE_PREFERENCES'
export const SET_THEME = 'active/SET_THEME'
export const SET_MODAL_OVERLAY = 'active/SET_MODAL_OVERLAY'
export const SET_ACTIVE_STATE = 'active/SET_ACTIVE_STATE'

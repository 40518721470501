import PhatInput from 'components/formElements/PhatInput'
import React, { useEffect, useMemo, useState } from 'react'
import Icon from 'components/elements/Icon'

import styles from './quantityInput.module.scss'

type Props = {
  quantity: number
  onQuantityUpdate: (quantity: number) => void
  className?: string
  dontAllowZero?: boolean
}

const QuantityInput = ({ quantity, dontAllowZero, onQuantityUpdate, className = '' }: Props) => {
  const [localQuantity, setLocalQuantity] = useState(quantity)

  const handleSetQuantity = () => onQuantityUpdate(Number(localQuantity) || 0)
  const handleSetDebouncedQty = (qty: number) => onQuantityUpdate(qty)

  const handleIncrementCard = () => setLocalQuantity(localQuantity + 1)
  const handleDecrementCard = () => setLocalQuantity(localQuantity - 1 > 0 ? localQuantity - 1 : 0)

  // IF the local quantity changes, update the parent quantity
  useEffect(() => {
    if (localQuantity === quantity) return

    const timeout = setTimeout(() => handleSetDebouncedQty(localQuantity), 660)

    return () => clearTimeout(timeout)
  }, [localQuantity])

  useEffect(() => {
    if (localQuantity === quantity) return

    setLocalQuantity(quantity)
  }, [quantity])

  return (
    <div className={`${styles.container} ${className}`}>
      <label>Quantity</label>

      <PhatInput
        selectOnFocus
        value={localQuantity}
        onChange={value => setLocalQuantity(Number(value))}
        placeholder="Card quantity"
        type="number"
        inputMode="numeric"
        min={0}
        onBlur={handleSetQuantity}
        className={styles.input}
      />

      <div className={styles.buttons}>
        <button
          disabled={localQuantity < (dontAllowZero ? 2 : 1)}
          className={styles.decrease}
          onClick={handleDecrementCard}>
          <Icon name="minus" />
        </button>

        <button className={styles.increase} onClick={handleIncrementCard}>
          <Icon name="plus" />
        </button>
      </div>
    </div>
  )
}

export default QuantityInput

import React from 'react'
import {
  ResponsiveContainer,
  Bar,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Line,
  ComposedChart,
  XAxis,
  YAxis,
} from 'recharts'

import { useAppSelector } from 'redux/hooks'

import Tooltip from 'components/tooltips/Tooltip'

import { getCardCMC } from 'utils/GetCardCMC'
import { useFrozenValue, useVisibility } from 'utils/useVisibility'

import { COLOR_OBJECT } from '../charts.const'

import styles from '../chart.module.scss'

type Props = { size?: number; autoWidth?: boolean }

type Data = {
  name: string
  White: number
  Blue: number
  Black: number
  Red: number
  Green: number
  Multicolor: number
  Colorless: number
  total: number
}

const ManaCurve = ({ size = 300, autoWidth = false }: Props) => {
  const cardMap = useAppSelector(state => state.deck.cardMap)
  const categories = useAppSelector(state => state.deck.categories)
  const [isVisible, elementRef] = useVisibility()

  const getCurveData = () => {
    let data = new Array<Data>()

    for (let i = 0; i <= 16; i++) {
      data.push({
        name: `${i}`,
        White: 0,
        Blue: 0,
        Black: 0,
        Red: 0,
        Green: 0,
        Multicolor: 0,
        Colorless: 0,
        total: 0,
      })
    }

    let highestCmc = 0
    let totalCmc = 0
    let totalCount = 0

    for (const card of Object.values(cardMap)) {
      const primaryCategory = categories[card.categories[0]]
      const cmc = getCardCMC(card)

      // If it's a land, or sideboard, or not inlcuded in deck - ignore it
      if (card.types.includes('Land') && !cmc) continue
      if (primaryCategory.name === 'Sideboard') continue
      if (!primaryCategory.includedInDeck) continue

      totalCmc += cmc * card.qty
      totalCount += card.qty

      if (cmc <= 16) {
        if (cmc > highestCmc) highestCmc = cmc

        const dataEntry: Data = { ...data[cmc] }
        const colors = card.front && card.front.colors.length ? card.front.colors : card.colors

        if (colors.length === 0) dataEntry.Colorless += card.qty
        else if (colors.length > 1) dataEntry.Multicolor += card.qty
        else dataEntry[colors[0]] += card.qty

        dataEntry.total += card.qty
        data[cmc] = dataEntry
      }
    }

    return {
      data: data.slice(0, highestCmc + 1),
      averageCMC: totalCmc / totalCount,
    }
  }

  const frozenData = useFrozenValue(() => getCurveData(), [cardMap, categories], isVisible)

  if (!frozenData) return null

  const { data, averageCMC } = frozenData

  const chart = (
    <ComposedChart height={size} width={size} data={data} style={{ marginLeft: '-25px' }}>
      <XAxis dataKey="name" />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <RechartsTooltip
        contentStyle={{ color: 'white', backgroundColor: '#5c5c5c', fontWeight: 'bold' }}
        labelFormatter={(cmc: string) => `${cmc} Mana value`}
      />
      <Bar dataKey="Colorless" stackId="a" fill={COLOR_OBJECT.Colorless} />
      <Bar dataKey="Multicolor" stackId="a" fill={COLOR_OBJECT.Multicolor} />
      <Bar dataKey="Green" stackId="a" fill={COLOR_OBJECT.Green} />
      <Bar dataKey="Red" stackId="a" fill={COLOR_OBJECT.Red} />
      <Bar dataKey="Black" stackId="a" fill={COLOR_OBJECT.Black} />
      <Bar dataKey="Blue" stackId="a" fill={COLOR_OBJECT.Blue} />
      <Bar dataKey="White" stackId="a" fill={COLOR_OBJECT.White} />
      <Line type="monotone" dataKey="total" stroke="#ff7300" />
    </ComposedChart>
  )

  return (
    <div ref={elementRef} className={styles.container} style={{ width: size }}>
      <div className={styles.header}>
        <h4>Mana Curve (Avg mana value: {averageCMC.toFixed(2)})</h4>
        <Tooltip
          content={
            <div>
              Avg mana value is the average of all non land cards mana value. <br />
              mana value stats are calculated using the official mana value of a card. Deck owners can go to a card's
              context menu to override a card's mana value with a custom mana value (eg: Cyclonic Rift 2 {'=>'} 7).
            </div>
          }
        />
      </div>
      {autoWidth ? (
        <ResponsiveContainer width={autoWidth ? '80%' : size} height={size}>
          {chart}
        </ResponsiveContainer>
      ) : (
        chart
      )}
    </div>
  )
}

export default ManaCurve

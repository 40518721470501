import React, { memo, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import SaveService from 'services/save.service'

import { CardType, SaveStatus, defaultCard } from 'types/deck'
import { defaultCollectionV2Card } from 'redux/collectionV2/types'

import { selectMenuCard, selectPreviousAndNextCard } from 'redux/deck/selectors'
import { modifySingleCardAction, setDeckStateAction } from 'redux/deck/actions'
import { setModalOverlay as setModalOverlayAction } from 'redux/active/actions'
import { useActions, useAppSelector } from 'redux/hooks'

import CardDetailsOverlay, { NextPrevOptions } from 'components/elements/Overlay/CardDetailsOverlay'
import CardForm from 'components/card/cardForm/cardForm'
import Rulings from 'components/card/cardForm/Rulings'
import InDecks from 'components/collectionV2/cardDetails/InDecks'
import RelatedCards from 'components/collectionV2/cardDetails/RelatedCards'
import CardInfo from 'components/card/CardInfo/cardInfo'
import CardEditionsGridOverlay from 'components/card/CardEditionsGridOverlay'

type CardDetailsTabTypes = 'options' | 'info' | 'rulings' | 'inDecks' | 'collectionRecords'

type Props = {
  open: boolean
  onClose: () => void
  card: CardType
  onCardUpdated: (card: CardType, removedCardIds?: number | number[]) => void
  saving?: SaveStatus
  nextPrevControls?: {
    previous: NextPrevOptions
    next: NextPrevOptions
  }
}

const CardModalStandalone = memo(({ open, card, onClose, onCardUpdated, saving, nextPrevControls }: Props) => {
  const [{ tbId: userId }] = useCookies(['tbId'])

  const [view, setView] = useState<CardDetailsTabTypes>('options')
  const [flipped, setFlipped] = useState(card?.flippedDefault || false)

  return (
    <CardDetailsOverlay
      open={open}
      card={card || defaultCard}
      onClose={onClose}
      activeTabId={view}
      onTabChange={setView}
      onCardFlipped={setFlipped}
      onCardUpdated={onCardUpdated}
      lockFlippedAction
      saving={saving}
      nextPrevControls={nextPrevControls}
      tabs={[
        {
          label: 'Card options',
          id: 'options',
          body: (
            <CardForm
              card={card || defaultCard}
              onCardUpdated={onCardUpdated}
              flipped={flipped}
              nextPrevControls={nextPrevControls}
            />
          ),
        },
        {
          label: 'In decks',
          id: 'inDecks',
          disabled: !userId,
          body: <InDecks card={card || defaultCard} />,
        },
        {
          label: 'Collection records',
          id: 'collectionRecords',
          disabled: !userId,
          body: <RelatedCards collectionCard={{ ...defaultCollectionV2Card, card: card || defaultCard }} />,
        },
        {
          label: 'Card info',
          id: 'info',
          body: <CardInfo card={card || defaultCard} flipped={flipped} />,
        },
        {
          label: 'Rulings',
          id: 'rulings',
          body: <Rulings card={card || defaultCard} />,
        },
      ]}
    />
  )
})

export default CardModalStandalone

export const CardModal = () => {
  const menuCard = useAppSelector(selectMenuCard)
  const openMenuCardToEditions = useAppSelector(state => state.deck.openMenuCardToEditions)

  const [card, setCard] = useState(menuCard || defaultCard)
  const [saving, setSaving] = useState<SaveStatus>('saved')

  useEffect(() => {
    if (menuCard) return setCard(menuCard)

    setTimeout(() => setCard(defaultCard), 200)
  }, [menuCard])

  const [setDeckState, setModalOverlay, modifySingleCard] = useActions(
    setDeckStateAction,
    setModalOverlayAction,
    modifySingleCardAction,
  )

  const [previous, next] = useAppSelector(state => selectPreviousAndNextCard(state, menuCard?.id || ''))

  const onClose = () => {
    setDeckState({ menuCardId: undefined, openMenuCardToEditions: false })
    setModalOverlay(null)
  }

  const onCardUpdated = (card: CardType) => {
    setSaving('saving')

    SaveService.save(card)
      .then(() => setSaving('saved'))
      .catch(() => setSaving('error'))
  }

  return (
    <>
      <CardModalStandalone
        open={!!menuCard && !openMenuCardToEditions}
        saving={saving}
        card={card}
        onClose={onClose}
        onCardUpdated={onCardUpdated}
        nextPrevControls={{
          previous: {
            label: previous?.name,
            onClick: () => setDeckState({ menuCardId: previous?.id }),
            disabled: !previous,
          },
          next: {
            label: next?.name,
            onClick: () => setDeckState({ menuCardId: next?.id }),
            disabled: !next,
          },
        }}
      />

      <CardEditionsGridOverlay
        open={openMenuCardToEditions && !!menuCard}
        card={menuCard || defaultCard}
        onClose={onClose}
        onCardSelected={onCardUpdated}
      />
    </>
  )
}

import React, { useState } from 'react'
import { ResponsiveContainer, BarChart, Tooltip, CartesianGrid, YAxis, XAxis, Bar } from 'recharts'
import Icon from 'components/elements/Icon'

import { useAppSelector } from 'redux/hooks'

import { useFrozenValue, useVisibility } from 'utils/useVisibility'

import { calculateChartHeight } from '../charts.const'

import styles from '../chart.module.scss'

type Props = { size?: number; autoWidth?: boolean }
type Data = { name: string; color: string; value: number }

const CategoryDistribution = ({ size = 275, autoWidth = false }: Props) => {
  const [autoHeight, setAutoHeight] = useState(false)

  const cardMap = useAppSelector(state => state.deck.cardMap)
  const categories = useAppSelector(state => state.deck.categories)
  const [isVisible, elementRef] = useVisibility()

  const categoryCounts = useFrozenValue(
    () =>
      Object.values(cardMap).reduce((acc, card) => {
        const primaryCategory = categories[card.categories[0]]

        // Not included, ignore them
        // Not including commander either since it seems obvious
        if (primaryCategory.isPremier || !primaryCategory.includedInDeck || primaryCategory.name === 'Sideboard')
          return acc

        for (const categoryName of card.categories) {
          if (acc[categoryName]) acc[categoryName] += 1 * card.qty
          else acc[categoryName] = 1 * card.qty
        }

        return acc
      }, {} as Record<string, number>),
    [cardMap, categories],
    isVisible,
  )

  if (!categoryCounts) return null

  const data: Data[] = Object.keys(categoryCounts)
    .map(type => ({
      name: type,
      value: categoryCounts[type],
      color: '#000',
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  const [height, disableAutoheight] = calculateChartHeight(size, autoHeight, data)

  const chart = (
    <BarChart height={height} width={size} data={data} maxBarSize={20} layout="vertical">
      <CartesianGrid strokeDasharray="3" />
      <XAxis type="number" />
      <YAxis type="category" dataKey="name" />
      <Bar dataKey="value" fill="#ffb157" />
      <Tooltip contentStyle={{ color: 'black', fontWeight: 'bold' }} cursor={{ fill: '#8b8b8b55' }} />
    </BarChart>
  )

  return (
    <div ref={elementRef} className={styles.container} style={{ width: size }}>
      <div className={styles.header}>
        <h4>
          Categories
          <button
            title="Expand"
            onClick={() => setAutoHeight(!autoHeight)}
            className={styles.extendButton}
            disabled={disableAutoheight}>
            <Icon name="arrows alternate vertical" />
          </button>
        </h4>
      </div>
      {autoWidth ? (
        <ResponsiveContainer width={autoWidth ? '80%' : size} height={height}>
          {chart}
        </ResponsiveContainer>
      ) : (
        chart
      )}
    </div>
  )
}

export default CategoryDistribution

import React, { useState } from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts'
import { Dropdown } from 'semantic-ui-react'

import { useAppSelector } from 'redux/hooks'

import { EDH_FORMATS } from 'types/deck'
import { Color } from 'services/apiTypes/card.types'

import { getCardsColorIdenty } from 'utils/cardHelpers'
import { useFrozenValue, useVisibility } from 'utils/useVisibility'
import { SINGLE_EXTRA_COLOR_COMMANDERS } from 'components/deckPage/header/Legality/legality.const'

import { COLOR_MAP, COLOR_OBJECT, REVERSE_COLOR_MAP } from '../charts.const'

import styles from './colorDistribution.module.scss'
import chartStyles from '../chart.module.scss'

type Props = { size?: number; autoWidth?: boolean }
type Data = { name: string; color: string; value: number }

const defaultColors: Record<string, number> = { W: 0, U: 0, B: 0, R: 0, G: 0, C: 0 }

const ColorDistributionPie = ({ size = 200, autoWidth = false }: Props) => {
  const [allColors, setAllColors] = useState(false)
  const [colorless, setColorless] = useState(false)
  const [landsOnly, setLandsOnly] = useState(false)

  const cardMap = useAppSelector(state => state.deck.cardMap)
  const format = useAppSelector(state => state.deck.format)
  const categories = useAppSelector(state => state.deck.categories)

  const deck = Object.values(cardMap)

  const isCommanderFormat = EDH_FORMATS.includes(format)

  const [isVisible, elementRef] = useVisibility()

  const getCostData = (): Data[] => {
    // prettier-ignore
    const colors = deck.reduce((acc, card) => {
      const primaryCategory = categories[card.categories[0]]

      if (primaryCategory.name === 'Sideboard' || !primaryCategory.includedInDeck) return acc

      const castingCost: string[] = card.front && card.back ? [...card.front.castingCost, ...card.back.castingCost] : card.castingCost

      for (const colorEntry of castingCost) {
        // Colored Mana
        if (acc[colorEntry] !== undefined) acc[colorEntry] += card.qty

        // Colorless Mana
        if (!isNaN(Number(colorEntry))) acc.C += Number(colorEntry) * card.qty

        // Hybrid Mana
        if (Array.isArray(colorEntry)) {
          for (const subColorEntry of colorEntry) {
            if (acc[subColorEntry] !== undefined) acc[subColorEntry] += card.qty
            if (!isNaN(subColorEntry)) acc.C += Number(subColorEntry) * card.qty
          }
        }
      }

      return acc
    }, {...defaultColors })

    const data = Object.keys(colors).reduce((acc, color) => {
      if (color === 'C' && !colorless) return acc
      if (colors[color] === 0) return acc

      const colorName = COLOR_MAP[color]

      acc.push({ name: `${colorName} Pips`, color: COLOR_OBJECT[colorName], value: colors[color] })

      return acc
    }, new Array<Data>())

    return data
  }

  const getManaProductionData = (): Data[] => {
    const commanders = deck.filter(card => {
      const primaryCategory = categories[card.categories[0]]

      return primaryCategory.isPremier
    })

    const allowsExtraColor = commanders.some(card => SINGLE_EXTRA_COLOR_COMMANDERS.includes(card.name))

    const deckColors = deck.reduce(getCardsColorIdenty, new Array<Color>()).map(c => REVERSE_COLOR_MAP[c])
    let premierColors = commanders.reduce(getCardsColorIdenty, new Array<Color>()).map(c => REVERSE_COLOR_MAP[c])

    if (allowsExtraColor && deckColors.length === premierColors.length + 1) premierColors = deckColors

    const commanderColors = allColors || !isCommanderFormat ? ['W', 'U', 'B', 'R', 'G'] : premierColors

    if (colorless) commanderColors.push('C')

    // prettier-ignore
    const manaProduction = deck.reduce((acc, card) => {
      const primaryCategory = categories[card.categories[0]]
      const types = [...card.types, ...(card.front?.types || []), ...(card.back?.types || [])]

      if (primaryCategory.name === 'Sideboard' || !primaryCategory.includedInDeck) return acc
      if (landsOnly && !types.includes('Land'))  return acc

      for (const color of Object.keys(card.manaProduction)) {
        acc[color] += card.manaProduction[color] * card.qty
      }

      return acc
    }, { ...defaultColors })

    const data = Object.keys(manaProduction).reduce((acc, color) => {
      if (!commanderColors.includes(color) || manaProduction[color] === 0) return acc

      const colorName = COLOR_MAP[color]

      acc.push({ name: `${colorName} Producers`, color: COLOR_OBJECT[colorName], value: manaProduction[color] })

      return acc
    }, new Array<Data>())

    return data
  }

  const costData = useFrozenValue(
    () => getCostData().sort((a, b) => (a.name > b.name ? 1 : -1)),
    [allColors, colorless, cardMap, format, categories],
    isVisible,
  )

  const manaProductionData = useFrozenValue(
    () => getManaProductionData().sort((a, b) => (a.name > b.name ? 1 : -1)),
    [allColors, colorless, cardMap, format, categories, landsOnly],
    isVisible,
  )

  if (!costData || !manaProductionData) return null

  // Recharts is fucking stupid and warns you every time it rerenders with a px height in a responseive container
  const chart = (
    <PieChart height={size} width={size}>
      <Pie dataKey={'value'} data={manaProductionData} cx={100} cy={100} outerRadius={60}>
        {manaProductionData.map((entry, index) => (
          <Cell key={index} fill={entry.color} stroke="#8e8e8e" />
        ))}
      </Pie>
      <Pie
        dataKey={'value'}
        data={costData}
        cx={100}
        cy={100}
        innerRadius={70}
        outerRadius={90}
        paddingAngle={costData.length > 1 ? 5 : 0}>
        {costData.map((entry, index) => (
          <Cell key={index} fill={entry.color} stroke="#8e8e8e" />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  )

  return (
    <div ref={elementRef} className={chartStyles.container} style={{ width: size }}>
      <div className={chartStyles.header}>
        <h4>Color Distribution</h4>
        <Dropdown text="Options" pointing="top right">
          <Dropdown.Menu>
            {isCommanderFormat && (
              <Dropdown.Item className={styles.option} onClick={() => setAllColors(!allColors)}>
                <label>Include non-identity colors</label>
                <input type="checkbox" checked={allColors} readOnly />
              </Dropdown.Item>
            )}

            <Dropdown.Item className={styles.option} onClick={() => setColorless(!colorless)}>
              <label>Include colorless</label>
              <input type="checkbox" checked={colorless} readOnly />
            </Dropdown.Item>

            <Dropdown.Item className={styles.option} onClick={() => setLandsOnly(!landsOnly)}>
              <label>Use lands only for production</label>
              <input type="checkbox" checked={landsOnly} readOnly />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {autoWidth ? (
        <ResponsiveContainer width={autoWidth ? '80%' : size} height={size}>
          {chart}
        </ResponsiveContainer>
      ) : (
        chart
      )}

      <div className={styles.labels}>
        <label>Outer: Mana Costs</label>
        <label>Inner: Mana Production</label>
      </div>
    </div>
  )
}

export default ColorDistributionPie

import React from 'react'
import { useCookies } from 'react-cookie'

import { disableFoilLayer } from 'services/accountSettings.service'

import styles from './cardOverlays.module.scss'

type Props = {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export const CardFilter = ({ children = null, className = '', style }: Props) => (
  <div className={`${styles.cardFilter} ${className}`} style={style}>
    {children}
  </div>
)

export const FoilDiv = (props: Props) => {
  const [{ [disableFoilLayer]: foilLayerIsDisabled }] = useCookies([disableFoilLayer])

  if (foilLayerIsDisabled) return null

  return <CardFilter {...props} className={`${props.className || ''} ${styles.foil}`} />
}

export const ErrorCard = (props: Props) => (
  <CardFilter {...props} className={`${props.className || ''} ${styles.error}`} />
)

export const SecondaryCategory = (props: Props) => (
  <CardFilter {...props} className={`${props.className || ''} ${styles.secondaryCategory}`} />
)

export const MultiSelected = (props: Props) => (
  <CardFilter {...props} className={`${props.className || ''} ${styles.multiSelected}`} />
)

import React, { useState } from 'react'
import { ResponsiveContainer, BarChart, Tooltip, CartesianGrid, YAxis, XAxis, Bar } from 'recharts'
import Icon from 'components/elements/Icon'

import { useAppSelector } from 'redux/hooks'

import { useFrozenValue, useVisibility } from 'utils/useVisibility'

import { calculateChartHeight } from '../charts.const'

import styles from '../chart.module.scss'

type Props = { size?: number; autoWidth?: boolean }

const CardTypeDistribution = ({ size = 275, autoWidth = false }: Props) => {
  const [autoHeight, setAutoHeight] = useState(false)

  const cardMap = useAppSelector(state => state.deck.cardMap)
  const categories = useAppSelector(state => state.deck.categories)

  const [isVisible, elementRef] = useVisibility()

  const getTypeData = () => {
    const dataObject: Record<string, number> = {}

    for (const card of Object.values(cardMap)) {
      const primaryCategory = categories[card.categories[0]]

      if (primaryCategory.name === 'Sideboard' || !primaryCategory.includedInDeck) continue

      const types =
        card.front && card.back
          ? Array.from(new Set([...card.front.types, ...card.back.types])) // uniquifies the types
          : card.types

      for (const type of types) {
        if (dataObject[type]) dataObject[type] += 1 * card.qty
        else dataObject[type] = card.qty
      }
    }

    const data = []

    for (const prop in dataObject) {
      if (dataObject[prop] > 0) data.push({ name: prop, value: dataObject[prop] })
    }

    return data
  }

  const data = useFrozenValue(
    () => getTypeData().sort((a, b) => (a.name > b.name ? 1 : -1)),
    [cardMap, categories],
    isVisible,
  )

  if (!data) return null

  const [height, disableAutoheight] = calculateChartHeight(size, autoHeight, data)

  const chart = (
    <BarChart height={height} width={size} data={data} maxBarSize={20} layout="vertical">
      <CartesianGrid strokeDasharray="3" />
      <XAxis type="number" />
      <YAxis type="category" dataKey="name" />
      <Bar dataKey="value" fill="#A1ACD4" />
      <Tooltip contentStyle={{ color: 'black', fontWeight: 'bold' }} cursor={{ fill: '#8b8b8b55' }} />
    </BarChart>
  )

  return (
    <div ref={elementRef} className={styles.container} style={{ width: size }}>
      <div className={styles.header}>
        <h4>
          Card Types
          <button
            title="Expand"
            onClick={() => setAutoHeight(!autoHeight)}
            className={styles.extendButton}
            disabled={disableAutoheight}>
            <Icon name="arrows alternate vertical" />
          </button>
        </h4>
      </div>
      {autoWidth ? (
        <ResponsiveContainer width={autoWidth ? '80%' : size} height={height}>
          {chart}
        </ResponsiveContainer>
      ) : (
        chart
      )}
    </div>
  )
}

export default CardTypeDistribution

import { RequestService } from 'services/request.service'

import { CardType } from 'types/deck'
import { SpellbookCombos, SpellbookResponse } from 'services/apiTypes/spellbook.types'

import { responseToCard } from 'utils/ResponseToResults'

export class SpellbookService extends RequestService {
  public combos = (commanders: CardType[], mainboard: CardType[]): Promise<SpellbookCombos> => {
    const commanderNames = commanders.map(commander => commander.name)
    const mainboardNames = mainboard.map(card => card.name)

    return super
      .post<SpellbookResponse>(`/api/cards/spellbook-combos/`, { commanders: commanderNames, main: mainboardNames })
      .then(res => ({
        almostIncluded: res.almostIncluded,
        included: res.included,

        // Serilaizing cards from DB to match existing CardType
        cards: Object.keys(res.cards).reduce((acc: Record<string, CardType>, oracleUid: string) => {
          const resCard = res.cards[oracleUid]
          const card = responseToCard(resCard)

          return { ...acc, [oracleUid]: card }
        }, {}),
      }))
  }
}

const spellbookService = new SpellbookService()

export default spellbookService

import React from 'react'
import Icon from 'components/elements/Icon'

import { CardType } from 'types/deck'

import styles from './categoryRow.module.scss'

type Props = {
  category: string
  card: CardType
  onCardChange: (card: CardType) => void
  disabled?: boolean
}

// Removes a category from a card
const RemoveButton = ({ category, card, onCardChange, disabled }: Props) => {
  const handleRemove = () => {
    if (!category || disabled) return

    const categories = card.categories.slice()

    categories.splice(categories.indexOf(category), 1)

    onCardChange({ ...card, categories })
  }

  return (
    <Icon
      className={styles.icon}
      style={{ cursor: disabled ? 'auto' : 'pointer', opacity: disabled ? 0.3 : 1 }}
      onClick={handleRemove}
      name="remove"
      title={disabled ? 'A card must have at least one category.' : ''}
    />
  )
}

export default RemoveButton

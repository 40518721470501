import React, { useEffect, useRef, useState } from 'react'
import { Icon, Tab } from 'semantic-ui-react'

import { useActionless, useAppSelector } from 'redux/hooks'
import { SET_ACTIVE_STATE } from 'redux/active/actions/types'

import { getRightPanelOpenDefault } from 'services/accountSettings.service'

import Charts from 'components/deckPage/RightPanel/Charts'
import Probability from 'components/deckPage/RightPanel/Probability'

import { basicKeybindSkips } from 'components/deckPage/modals/KeybindsOverlay'

import styles from './rightPanel.module.scss'

const tabs = [
  { menuItem: 'Stats', render: () => <Charts /> },
  { menuItem: 'Probability', render: () => <Probability /> },
]

const RightPanelV2 = () => {
  const rightPanelOpen = useAppSelector(state => state.active.rightPanelOpen)
  const [setActiveState] = useActionless(SET_ACTIVE_STATE)

  const keylistener = useRef<(e: KeyboardEvent) => void>(() => null)

  const [mounted, setMounted] = useState(false)
  const [locked, setLocked] = useState(false)
  const [tab, setTab] = useState(rightPanelOpen === 'buy' ? 2 : 0)

  const handleTabChange = (_e: any, { activeIndex }: any) => setTab(activeIndex)

  useEffect(() => {
    setLocked(getRightPanelOpenDefault())
    setMounted(true)
  }, [])

  useEffect(() => {
    window.removeEventListener('keydown', keylistener.current)

    if (!rightPanelOpen) {
      document.body.classList.remove('no-scrolling')

      return
    }

    if (!locked) document.body.classList.add('no-scrolling')
    else document.body.classList.remove('no-scrolling')

    if (rightPanelOpen === 'stats') setTab(0)
    if (rightPanelOpen === 'probability') setTab(1)

    keylistener.current = (e: KeyboardEvent) => {
      if (basicKeybindSkips(e)) return
      if (e.key === 'Escape' && !locked) {
        e.preventDefault()

        setActiveState({ rightPanelOpen: undefined })
      }
    }

    window.addEventListener('keydown', keylistener.current)

    return () => window.removeEventListener('keydown', keylistener.current)
  }, [rightPanelOpen, locked])

  return (
    <>
      {locked && <div className={styles.lockedSpacer} />}
      {!!rightPanelOpen && !locked && mounted && (
        <div onClick={() => setActiveState({ rightPanelOpen: false })} className={styles.dimmer} />
      )}
      <div className={`${styles.controls} ${rightPanelOpen ? styles.open : ''}`}>
        {rightPanelOpen && (
          <button className={`${styles.button} ${styles.lockButton}`} onClick={() => setLocked(!locked)}>
            <Icon name={locked ? 'unlock' : 'lock'} />
            {locked ? 'Unlock' : 'Lock'}
          </button>
        )}
      </div>
      <div className={`${styles.container} ${rightPanelOpen ? styles.open : ''} ${locked ? styles.locked : ''}`}>
        <div className={styles.content}>
          {rightPanelOpen ? (
            <Tab
              id="tabs"
              className={styles.tabs}
              menu={{ secondary: true, pointing: true }}
              panes={tabs}
              activeIndex={tab}
              onTabChange={handleTabChange}
            />
          ) : null}
        </div>
      </div>
    </>
  )
}

export default RightPanelV2

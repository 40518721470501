import { CardType } from 'types/deck'
import { MODIFY_CARD_MAP } from 'redux/deck/actions/types'

// import { store } from 'redux/store'

export type ModifyCardMapActionType = {
  type: typeof MODIFY_CARD_MAP
  payload: {
    cards: CardType[]
    removals: string[]
    skipActionStack: boolean
    isUndo?: boolean
    isRedo?: boolean
  }
}

export const modifyCardMapAction = (
  cards: CardType | CardType[],
  removals?: string | string[],
  options?: { skipActionStack?: boolean; isUndo?: boolean; isRedo?: boolean },
): ModifyCardMapActionType => {
  const toAdd = Array.isArray(cards) ? cards : [cards]
  const toRemove = removals ? (Array.isArray(removals) ? removals : [removals]) : []
  return {
    type: MODIFY_CARD_MAP,
    payload: {
      cards: toAdd,
      removals: toRemove,
      skipActionStack: options?.skipActionStack || false,
      isUndo: options?.isUndo || false,
      isRedo: options?.isRedo || false,
    },
  }
}

// Updates a single card (or removes it if it's quantity is less than 1)
// This is the simpler version of the above. Covers 99% of cases when it comes to updating cards
export const modifySingleCardAction = (card: CardType): ModifyCardMapActionType => {
  // If we don't have a quantity, just remove it
  if (card.qty < 1)
    return {
      type: MODIFY_CARD_MAP,
      payload: {
        cards: [],
        removals: [card.id],
        skipActionStack: false,
      },
    }

  return {
    type: MODIFY_CARD_MAP,
    payload: {
      cards: [card],
      removals: [],
      skipActionStack: false,
    },
  }
}

import shortid from 'shortid'

import { RequestService } from 'services/request.service'

import DeckService from 'services/deck.service'
import ToastService from 'services/toast.service'

import { store } from 'redux/store'
import { MODIFY_CARD_MAP, SET_DECK_STATE } from 'redux/deck/actions/types'

import { CardType } from 'types/deck'
import { ModifyDeckResponse } from 'services/apiTypes/save.types'
import { DeckResponse } from 'services/apiTypes/deck.types'
import { WebsocketV2Resposne } from 'services/apiTypes/websocket.types'

import { generateInitialDeckState } from 'redux/deck/initialState'
import { deckResponseToDeckState } from 'utils/responseToDeck'

import CookieService from './cookie.service'
import WebsocketService from 'services/websocket.service'

import { getDisableCollaborativeToast } from 'services/accountSettings.service'

import SaveError from 'components/toasts/SaveError'

const TOAST_HEADER = 'Deck save service'

type SaveType = 'add' | 'modify' | 'remove'

type SaveRequest = {
  deckRelationId?: string | number
  action: SaveType
  cardid: string
  categories: string[]
  patchId: string
  modifications: {
    quantity: number
    modifier: string
    customCmc: number | null
    companion: boolean
    flippedDefault: boolean
    label: string
  }
}

type SaveRequestData = {
  updatedCards: SaveRequest[]
  cards: CardType[]
  removedCards: CardType[]
  removedIds: string[]
  deckId: string | number
  cardMap: Record<string, CardType>
}

export class SaveService extends RequestService {
  public save = async (cardsArg: CardType | CardType[] = [], removedIdsArg: string | string[] = []): Promise<void> => {
    const saveObject = this.prepareSave(cardsArg, removedIdsArg)

    if (this.bypassSaveRequest()) {
      // Set a deckRelationId for new cards that don't have one yet (this is for the sandbox. The deckRelationId us used to know if a card is being added vs updated)
      const cards = saveObject.cards.map(card => ({
        ...card,
        deckRelationId: card.deckRelationId || shortid.generate(),
      }))

      store.dispatch({
        type: MODIFY_CARD_MAP,
        payload: { cards: cards, removals: saveObject.removedIds, skipActionStack: false },
      })

      // Note - We're using the cards that DO NOT have the deckRelationId set. This is because the deckRelationId is used to determine if a card is being added or updated. If it's not set, it's a new card
      this.generateChangeToast(saveObject.updatedCards, saveObject.cards, saveObject.removedCards)

      return
    }

    // prettier-ignore
    if (this.serverContext) return Promise.reject('Cannot save cards on the server, only the client. Process relies on redux')

    return this.makeSaveRequest(saveObject)
      .then(({ cards, removals }) => {
        store.dispatch({ type: MODIFY_CARD_MAP, payload: { cards, removals, skipActionStack: false } })
        store.dispatch({ type: SET_DECK_STATE, payload: { saving: 'saved' } })

        WebsocketService.send({ cards, removals: saveObject.removedIds }, 'MODIFY_CARD_MAP')

        this.generateChangeToast(saveObject.updatedCards, cards, saveObject.removedCards)
      })
      .catch(err => {})
  }

  public overwriteDeck = async (deckResponse: DeckResponse): Promise<any> => {
    const { deck: deckState } = store.getState()
    const { cardMap, id: deckId } = deckState

    const removedCards = Object.values(cardMap)
    const cleanDeckState = generateInitialDeckState()
    const mergedDeckStates = deckResponseToDeckState(cleanDeckState, deckResponse)

    const addedCards = Object.values(mergedDeckStates.cardMap).map(card => ({ ...card, deckRelationId: '' })) // Set deckRelationId to empty string to force the save service to see it as a new card

    return this.save(
      [...addedCards, ...removedCards],
      removedCards.map(card => card.id),
    ).then(() => {
      const updatedDeckMeta = { customFeatured: deckResponse.customFeatured, featured: deckResponse.featured }

      return DeckService.update(deckId, updatedDeckMeta)
        .then(() => store.dispatch({ type: SET_DECK_STATE, payload: updatedDeckMeta }))
        .catch(() => ToastService.create('Failed to featured deck image', 'Save Service', 'error'))
    })
  }

  public undo = async (): Promise<void> => {
    const { actionStack } = store.getState().deck

    if (!actionStack.length) return

    const lastAction = actionStack[actionStack.length - 1]
    const { cards: undoneCards, removals: undoneRemovals } = lastAction.payload

    const saveObject = this.prepareSave(undoneCards, undoneRemovals)

    return this.makeSaveRequest(saveObject).then(({ cards, removals }) => {
      store.dispatch({ type: MODIFY_CARD_MAP, payload: { cards, removals, skipActionStack: false, isUndo: true } })
      store.dispatch({ type: SET_DECK_STATE, payload: { saving: 'saved' } })

      const message = this.generateChangeToast(saveObject.updatedCards, cards, saveObject.removedCards, {
        skipToast: true,
        allowSingleChangeToast: true,
      })

      WebsocketService.send({ cards, removals: undoneRemovals }, 'MODIFY_CARD_MAP')

      if (message) ToastService.create(`Action undone - ${message}`, TOAST_HEADER, 'success')
    })
  }

  public redo = async (): Promise<void> => {
    const { undoneActionStack } = store.getState().deck

    if (!undoneActionStack.length) return

    const undoneLastAction = undoneActionStack[undoneActionStack.length - 1]
    const { cards: undoneCards, removals: redoneRemovals } = undoneLastAction.payload

    const saveObject = this.prepareSave(undoneCards, redoneRemovals)

    return this.makeSaveRequest(saveObject).then(({ cards, removals }) => {
      store.dispatch({ type: MODIFY_CARD_MAP, payload: { cards, removals, skipActionStack: false, isRedo: true } })
      store.dispatch({ type: SET_DECK_STATE, payload: { saving: 'saved' } })

      const message = this.generateChangeToast(saveObject.updatedCards, cards, saveObject.removedCards, {
        skipToast: true,
        allowSingleChangeToast: true,
      })

      WebsocketService.send({ cards, removals: redoneRemovals }, 'MODIFY_CARD_MAP')

      if (message) ToastService.create(`Action redone - ${message}`, TOAST_HEADER, 'success')
    })
  }

  public collaborativeChange = (wsCallbackPayload: WebsocketV2Resposne) => {
    const cards = wsCallbackPayload?.payload?.cards || []
    const removals = wsCallbackPayload?.payload?.removals || []
    const username = wsCallbackPayload?.userInfo?.username || ''

    const saveObject = this.prepareSave(cards, removals, true)
    const message = this.generateChangeToast(saveObject.updatedCards, cards, saveObject.removedCards, {
      allowSingleChangeToast: true,
      skipToast: true,
    })

    store.dispatch({ type: MODIFY_CARD_MAP, payload: { cards, removals, skipActionStack: true } })

    if (getDisableCollaborativeToast()) return

    ToastService.create((username ? `${username} - ` : '') + message, 'Collaborative Service', 'success')
  }

  private generateChangeToast(
    addedOrUpdatedRequest: SaveRequest[],
    addedOrUpdatedCards: CardType[],
    removedCards: CardType[],
    {
      skipToast = false,
      allowSingleChangeToast = false,
    }: { skipToast?: boolean; allowSingleChangeToast?: boolean } = {}, // Options
  ): string | null {
    const addedCards = addedOrUpdatedRequest.filter(request => request.action === 'add')
    const modifiedCards = addedOrUpdatedRequest.filter(request => request.action === 'modify')

    const cardMap: Record<string, CardType> = addedOrUpdatedCards.reduce(
      // @ts-ignore - Typing is wrong here. patchId exists
      // card.id is the deckRelationId, card.patchId is the patchId - you won't have the deckRelationId for new cards or cards added and called back from collaborative
      (acc, card) => ({ ...acc, [card.id]: card, [card.patchId]: card }),
      {},
    )

    const didRemoveCards = removedCards.length > 0
    const didModifyCards = modifiedCards.length > 0
    const didAddCards = addedCards.length > 0

    let message = 'Updated card(s)'

    if (didAddCards && !didModifyCards && !didRemoveCards) {
      if (addedCards.length === 1) message = `Added ${cardMap[addedCards[0].patchId]?.name}`
      else message = `Added ${addedCards.length} card(s)`
    }

    if (didModifyCards && !didAddCards && !didRemoveCards) {
      if (!allowSingleChangeToast && modifiedCards.length === 1) return null

      if (modifiedCards.length === 1) message = `Updated ${cardMap[modifiedCards[0].patchId]?.name}`
      else message = `Updated ${modifiedCards.length} card(s)`
    }

    if (didRemoveCards && !didAddCards && !didModifyCards) {
      if (!allowSingleChangeToast && removedCards.length === 1) return null

      if (removedCards.length === 1) message = `Removed ${removedCards[0].name}`
      else message = `Removed ${removedCards.length} card(s)`
    }

    if (didAddCards && didModifyCards && !didRemoveCards) {
      message = `Added ${addedCards.length} card(s), updated ${modifiedCards.length} card(s)`
    }

    if (didAddCards && !didModifyCards && didRemoveCards) {
      message = `Added ${addedCards.length} card(s), removed ${removedCards.length} card(s)`
    }

    if (!didAddCards && didModifyCards && didRemoveCards) {
      message = `Updated ${modifiedCards.length} card(s), removed ${removedCards.length} card(s)`
    }

    if (didAddCards && didModifyCards && didRemoveCards) {
      message = `Added ${addedCards.length} card(s), updated ${modifiedCards.length} card(s), removed ${removedCards.length} card(s)`
    }

    if (!skipToast) ToastService.create(message, TOAST_HEADER, 'success')

    return message
  }

  private makeSaveRequest = async ({
    cards,
    deckId,
    updatedCards,
    removedIds,
    cardMap,
  }: SaveRequestData): Promise<{ cards: CardType[]; removals: string[] }> => {
    store.dispatch({ type: SET_DECK_STATE, payload: { saving: 'saving' } })

    try {
      const res = await super.patch<ModifyDeckResponse>(`/api/decks/${deckId}/modifyCards/v2/`, { cards: updatedCards })

      const saturatedCards = cards
        .filter(card => !removedIds.includes(card.id))
        .map(card => {
          const deckRelationId = res.add.find(c => c.patchId === card.id)?.deckRelationId

          return {
            ...card,
            // Populating the deckRelationId from the response for newly added cards (use the existing deckRelationId for existing cards)
            id: card.deckRelationId || `${deckRelationId || card.id}`,
            deckRelationId: card.deckRelationId || `${res.add.find(c => c.patchId === card.id)?.deckRelationId || ''}`,

            // Keep the existing patch ID around for the toast message - needed to match pre request cards to post request cards (when they're added and didn't yet have a deckRelationId)
            patchId: card.id,
          }
        })

      return { cards: saturatedCards, removals: removedIds }
    } catch (err: any) {
      // err.name is applicable for 400s with no response body or no shape
      // Trying our best to get a useful error message for the user
      const error = err?.stack ? err.stack : err

      ToastService.createCustomToast(SaveError, 'error', { error, body: { updatedCards } })

      // Debounced changes are only applicable to cards already existing in the deck (eg: changing the visual quantity, or foiling)
      const modifiedCardIds = cards.filter(card => !!card.deckRelationId).map(card => card.id)
      const rollbackCards = modifiedCardIds.map(id => cardMap[id])

      // Forcing a re-render to reset any debounced changes that may have been made
      store.dispatch({ type: MODIFY_CARD_MAP, payload: { cards: rollbackCards, removals: [], skipActionStack: true } })
      store.dispatch({ type: SET_DECK_STATE, payload: { saving: 'error' } })

      // Throwing again so we can allow the parent to handle any extra errors if needed
      throw err
    }
  }

  private prepareSave = (
    cardsArg: CardType | CardType[] = [],
    removedIdsArg: string | string[] = [],
    collaborativeChange: boolean = false,
  ): SaveRequestData => {
    const { deck: deckStore } = store.getState()

    const deckId = deckStore.id
    const cardMap = deckStore.cardMap

    let cards = Array.isArray(cardsArg) ? cardsArg : [cardsArg]
    let removedIds = Array.isArray(removedIdsArg) ? removedIdsArg : [removedIdsArg]

    const removedCards = removedIds.map(id => cardMap[id])

    // If no cards are passed, assume we're recieving only an array of removed card ids
    if (!cards.length && removedCards.length) cards = removedCards

    const updatedCards = cards.map(card => {
      let action: SaveType = 'add'

      if (card?.deckRelationId) {
        if (!collaborativeChange) action = 'modify'
        // If this is a collaborative change, we need to check if the card is being modified or added. The deckRelationId will be present for cards that coming from collaboration
        else {
          const existingCard = cardMap[card.id]

          if (existingCard) action = 'modify'
        }
      }

      if (removedIds.includes(card.id) || card.qty < 1) {
        action = 'remove'

        // In the instance where a card is removed by setting its quantity to 0, we need to ensure it's removed from the deck redux state correctly. Setup the removed info to match if a card was hard deleted
        if (!removedIds.includes(card.id)) {
          removedIds.push(card.id)
          removedCards.push(card)
        }
      }

      const cardRequest: SaveRequest = {
        // deckRelationId: card.deckRelationId || null, // Cannot be present if action is 'add' - django serializer stupidity. This should be fixed API side
        action,
        cardid: card.cardId,
        categories: card.categories,
        patchId: card.id,
        modifications: {
          quantity: card.qty || 1,
          modifier: card.modifier,
          customCmc: card.customCmc,
          companion: card.companion || false,
          flippedDefault: card.flippedDefault || false,
          label: `${card.colorLabel.name},${card.colorLabel.color}`,
        },
      }

      if (action === 'remove' || action === 'modify') cardRequest.deckRelationId = card.deckRelationId || 0

      return cardRequest
    })

    return { updatedCards, cards, removedCards, removedIds, deckId, cardMap }
  }

  private bypassSaveRequest = (): boolean => {
    const userId = Number(CookieService.get('tbId') || 0)
    const { ownerid, editors } = store.getState().deck

    const canEdit = ownerid === userId || editors.includes(userId)

    if (window.location.pathname.includes('/sandbox')) return true
    if (!canEdit) return true

    return false
  }
}

const saveService = new SaveService()

export default saveService

import { RequestService } from 'services/request.service'
import SearchHistoryService from './searchHistory.service'

import { responseToCard } from 'utils/ResponseToResults'

import { CardType, FORMAT } from 'types/deck'

import { SearchOptions, RecsOptions, CardListResponse, OptimizationOptions, Ruling } from 'services/apiTypes/card.types'

const appendStringOrArray = (value: any) =>
  Array.isArray(value) ? value.map(v => encodeURIComponent(v)).join(',') : encodeURIComponent(value)

const takeTrueKeys = (object: Record<string, boolean>) => {
  const values = []

  for (const key in object) {
    if (!object[key]) continue

    values.push(key)
  }

  return values.join(',')
}

const checkStringArrayTruthyness = (array?: any) => {
  if (!array) return false
  if (!Array.isArray(array) && array) return true
  if (Array.isArray(array) && array.length === 0) return false

  return true
}

// Rips off everything before /api/ in the request url
// We have to do this because django is fucking stupid
// The next url that's being returned doesn't have https on it since the intercommunication within kubernetes is not https
// Because of this django serializes all next URLs to http
// Stripping off the domain and just leaving the route will allow our request service to apply the correct protocol
export const fixDangjosStupidFuckingInternalProtocolIssue = (url: string | null) =>
  url ? `/api/${url.split('/api/').slice(1).join('/api/')}` : ''

export class CardService extends RequestService {
  public async list(
    options: SearchOptions,
    next: string | null = null,
    saveHistory = false,
  ): Promise<CardListResponse> {
    let url = next || '/api/cards/v2/?'

    // Cannot set colorless while using and, might just wanna remove this from the api
    if (options.andcolors && options.colors) options.colors.Colorless = false
    if (!options.andcolors && options.colors) options.colors.Colorless = false

    // If we have a next URL, skip all provided params and just use the next url
    if (!next) {
      if (options.search) url += `nameSearch=${options.search}&`
      if (options.name) url += `name=${appendStringOrArray(options.name)}&`
      if (options.exact) url += 'exact&'
      if (checkStringArrayTruthyness(options.editions)) url += `edition=${appendStringOrArray(options.editions)}&`
      if (options.editionSearch) url += `editionSearch=${options.editionSearch}&`
      if (checkStringArrayTruthyness(options.superTypes))
        url += `superTypes=${appendStringOrArray(options.superTypes)}&`
      if (checkStringArrayTruthyness(options.types)) url += `types=${appendStringOrArray(options.types)}&`
      if (checkStringArrayTruthyness(options.subTypes)) url += `subTypes=${appendStringOrArray(options.subTypes)}&`
      if (options.random) url += `random&`
      if (options.formatLegality && options.formatLegality !== FORMAT.CUSTOM)
        url += `formatLegality=${options.formatLegality}&`
      if (options.includeTokens) url += `includeTokens&`
      if (options.includeDigital) url += `includeDigital&`
      if (options.includeOversized) url += `includeOversized&`
      if (options.includeEmblems) url += `includeEmblems&`
      if (options.includeArtCards) url += `includeArtCards&`
      if (checkStringArrayTruthyness(options.ids)) url += `ids=${appendStringOrArray(options.ids)}&`
      if (checkStringArrayTruthyness(options.oracleCardIds))
        url += `oracleCardIds=${appendStringOrArray(options.oracleCardIds)}&`
      if (checkStringArrayTruthyness(options.uids)) url += `uids=${appendStringOrArray(options.uids)}&`
      if (options.oracleText) url += `text=${encodeURIComponent(options.oracleText)}&`
      if (options.manaCost) url += `manaCost=${options.manaCost}&`
      if (options.flavor) url += `flavor=${options.flavor}&`
      if (options.artist) url += `artist=${options.artist}&`
      if (options.collectorNumber) url += `collectorNumber=${options.collectorNumber}&`
      if (options.lore) url += `lore=${options.lore}&`
      if (!options.allEditions) url += `unique&` // Flipping this because it's a better default
      if (options.game) url += `game=${options.game}&`
      if (options.collection) url += `collection=${options.collection}&`
      if (options.colors) url += `colors=${takeTrueKeys(options.colors)}&`
      if (options.orderBy && options.descending) url += `orderBy=-${options.orderBy}&`
      if (options.orderBy && !options.descending) url += `orderBy=${options.orderBy}&` // this is the default if orderBy is provided
      if (options.andcolors) url += `andcolors=true&`
      if (options.colorIdentity) url += `colorIdentity=true&`
      if (options.rarity) url += `rarity=${takeTrueKeys(options.rarity)}&`
      if (options.cmc) url += `cmc=${options.cmc}&`
      if (options.cmc !== undefined && options.gtecmc) url += `gtecmc&`
      if (options.cmc !== undefined && options.ltecmc) url += `ltecmc&`
      if (options.power) url += `power=${options.power}&`
      if (options.power !== undefined && options.gtepower) url += `gtepower&`
      if (options.power !== undefined && options.ltepower) url += `ltepower&`
      if (options.toughness) url += `toughness=${options.toughness}&`
      if (options.toughness !== undefined && options.gtetoughness) url += `gtetoughness&`
      if (options.toughness !== undefined && options.ltetoughness) url += `ltetoughness&`
      if (options.hideUniverseBeyond !== undefined && options.hideUniverseBeyond) url += `hideUniverseBeyond&`
      if (options.page) url += `page=${options.page}&`
    }

    const res = await super.get(url)

    const cards = res.results.map((card: any) => responseToCard(card))
    const newNext = fixDangjosStupidFuckingInternalProtocolIssue(res.next)
    const total = res.count

    if (saveHistory && total > 0) SearchHistoryService.writeArchidektSearch(options)

    return { cards, next: newNext, total }
  }

  public async listCardsOnly(options: SearchOptions): Promise<CardType[]> {
    const { cards } = await this.list(options)

    return cards
  }

  public async scryfallSearch(
    scrySearchString: string,
    next?: string | null,
    saveHistory = false,
    page = 1,
    includeExtras?: boolean,
  ): Promise<CardListResponse> {
    let requestUrl = next || `/api/cards/scryfall/?search=${encodeURIComponent(scrySearchString)}&page=${page}`

    if (includeExtras) requestUrl += '&includeExtras=1'

    const res = await super.get(requestUrl)
    const cards = res.results.map((card: any) => responseToCard(card))
    const newNext = fixDangjosStupidFuckingInternalProtocolIssue(res.next)

    if (saveHistory && res.count > 0) SearchHistoryService.writeScryfallSearch(scrySearchString)

    return { cards, next: newNext, total: res.count }
  }

  public async scryfallCardsOnly(
    scrySearchString: string,
    next?: string,
    saveHistory = false,
    page = 1,
    includeExtras?: boolean,
  ): Promise<CardType[]> {
    const { cards } = await this.scryfallSearch(scrySearchString, next, saveHistory, page, includeExtras)

    return cards
  }

  public async edhRecs(commanders: CardType[], mainboard: CardType[]): Promise<CardType[]> {
    const commaderRequestFormat = commanders.map(c => c.front?.name || c.name)
    const cards = mainboard.map(card => `${card.qty} ${card.front?.name || card.name}`)

    const res = await super.post(`/api/recommendations/edhrec/`, { commanders: commaderRequestFormat, cards })

    return res.map((card: any) => responseToCard(card))
  }

  public async archidektRecs(options: RecsOptions): Promise<CardType[]> {
    let url = '/api/recommendations/?'

    if (options.type) url += `type=${options.type}&`
    if (options.gameFormat) url += `gameFormat=${options.gameFormat}&`
    if (options.name) url += `name=${options.name}&`
    if (options.cards) url += `cards=${appendStringOrArray(options.cards)}&`
    if (options.collection) url += `collection=${options.collection}&`

    const res = await super.get(url)

    return res.results.map((card: any) => responseToCard(card))
  }

  public async detail(cardId: string | number): Promise<CardType> {
    const res = await super.get(`/api/cards/${cardId}/`)

    return responseToCard(res)
  }

  public async autocomplete(searchString: string): Promise<string[]> {
    const res = await super.get('/api/cards/auto/?string=' + searchString)
    return res.results.map((card: { name: string }) => card.name)
  }

  public async optimizeCards(optimizationOptions: OptimizationOptions, nextUrl?: string): Promise<CardListResponse> {
    let queryString = nextUrl || '/api/cards/optimize/?'

    if (!nextUrl) {
      if (optimizationOptions.oracleIds)
        queryString += `oracleIds=${appendStringOrArray(optimizationOptions.oracleIds)}&`
      if (optimizationOptions.priceSource) queryString += `priceSource=${optimizationOptions.priceSource}&`
      if (optimizationOptions.preferFoil) queryString += `preferFoil=1&`
      if (optimizationOptions.preferFancyCardLook) queryString += `preferFancyCardLook=1&`
      if (optimizationOptions.preferExpensive) queryString += `preferExpensive=1&`
      if (optimizationOptions.preferOld) queryString += `preferOld=1&`
      if (optimizationOptions.preferNew) queryString += `preferNew=1&`
      if (optimizationOptions.preferRetroFrames) queryString += `preferRetroFrames=1&`
      if (optimizationOptions.excludeNormal) queryString += `excludeNormal=1&`
      if (optimizationOptions.excludeInverted) queryString += `excludeInverted=1&`
      if (optimizationOptions.excludeExtendedArt) queryString += `excludeExtendedArt=1&`
      if (optimizationOptions.excludeFullArt) queryString += `excludeFullArt=1&`
      if (optimizationOptions.excludeBorderless) queryString += `excludeBorderless=1&`
      if (optimizationOptions.excludeMasterpiece) queryString += `excludeMasterpiece=1&`
      if (optimizationOptions.excludeShowcase) queryString += `excludeShowcase=1&`
      if (optimizationOptions.excludePromo) queryString += `excludePromo=1&`
      if (optimizationOptions.includeDigital) queryString += `includeDigital=1&`
      if (optimizationOptions.includeGoldBorder) queryString += `includeGoldBorder=1&`
      if (optimizationOptions.limitToCollection) queryString += `limitToCollection=1&`
    }

    const res = await super.get(queryString)

    const cards = res.results.map((card: any) => responseToCard(card))
    const newNext = fixDangjosStupidFuckingInternalProtocolIssue(res.next)
    const total = res.count

    return { cards, next: newNext, total }
  }

  public async rulings(cardUid: string): Promise<Ruling[]> {
    return this.get(`/api/cards/${cardUid}/rulings/`)
  }
}

const cardService = new CardService()

export default cardService

import shortid from 'shortid'

import { wsUrl } from 'environment'
import { getToken } from 'utils/token.utils'

import { store } from 'redux/store'
import { DECK_PATCH_SUCCEEDED, SET_DECK_STATE } from 'redux/deck/actions/types'

import ToastService from 'services/toast.service'
import saveService from 'services/save.service'

import { WebsocketV2Resposne, MessageType } from 'services/apiTypes/websocket.types'

class WebsocketService {
  deckId: string | number | null
  clientId: string // Unique identifier for this instance of the site (tab, browser, device, etc)

  connection: WebSocket | null

  constructor() {
    this.deckId = null
    this.clientId = shortid.generate()
  }

  initialize = (deckId: string | number) => {
    this.deckId = deckId

    if (!this.connection) this.connect()
  }

  uninitialize = () => {
    this.connection?.close()

    this.connection = null
    this.deckId = null
  }

  connect = () => {
    if (!this.deckId) return Promise.resolve() // Sandbox mode, no need for collaborative connection

    return new Promise(async (resolve, reject) => {
      if (!this.deckId) return reject('No deck id provided')

      const token = await getToken()
      const connectionUrl = `${wsUrl}/api/ws/collaborative/${this.deckId}/?clientId=${this.clientId}&token=${token}`

      this.connection = new WebSocket(connectionUrl)

      this.connection.onmessage = this.onMessage

      this.connection.onopen = () => this.onOpen(resolve)
      this.connection.onclose = this.onClose

      this.connection.onerror = err => this.onError(err, reject)
    })
  }

  send = (payload: any, reduxActionType: MessageType, attemptConnection = true): Promise<void> => {
    // prettier-ignore
    if (!this.connection && attemptConnection) return this.connect()?.then(() => this.send(payload, reduxActionType, false))
    if (!this.connection) return Promise.reject()

    this.connection.send(JSON.stringify({ payload, type: reduxActionType }))

    return Promise.resolve()
  }

  onMessage = (response: MessageEvent) => {
    try {
      const ws: WebsocketV2Resposne = JSON.parse(response.data)

      const status = ws?.status
      const action = ws?.action
      const generatedBySelf = ws?.userInfo?.clientId === this.clientId

      if (status === 'error' && ws.payload) return ToastService.create(ws.payload, 'Connection Service', 'error')

      if (status !== 'success') return

      if (!action && ws.payload) return ToastService.create(ws.payload, 'Connection Service', 'warning')

      if (generatedBySelf) return

      if (action === 'MODIFY_CARD_MAP') return saveService.collaborativeChange(ws)
      if (action === 'SET_DECK_STATE') return store.dispatch({ type: SET_DECK_STATE, payload: ws.payload })
      if (action === 'DECK_PATCH_SUCCEEDED') return store.dispatch({ type: DECK_PATCH_SUCCEEDED, payload: ws.payload })
    } catch (err) {
      console.error('Error parsing websocket message:', err)
    }
  }

  onOpen = (resolve: any) => {
    store.dispatch({ type: SET_DECK_STATE, payload: { wsConnectionOpen: true } })

    resolve()
  }

  onClose = () => {
    this.connection = null

    store.dispatch({ type: SET_DECK_STATE, payload: { wsConnectionOpen: false } })
  }

  onError = (err: any, reject: any) => {
    this.connection?.close()
    this.connection = null

    store.dispatch({ type: SET_DECK_STATE, payload: { wsConnectionOpen: false } })

    reject(err)
  }
}

const websocketService = new WebsocketService()

export default websocketService

import React, { useState, useEffect, useRef } from 'react'
import { Button, Icon } from 'semantic-ui-react'

import { useAppSelector } from 'redux/hooks'

import { CardListResponse } from 'services/apiTypes/card.types'
import CardService from 'services/card.service'

import { ScryfallSearchMeta } from 'types/searchV2'

import Input from 'components/formElements/SemanticInput'

import styles from './scryfallSearchForm.module.scss'
import { getStickyDeckSearch } from 'services/accountSettings.service'
import PhatButton from 'components/formElements/PhatButton'

type Props = {
  onSubmitted: (search: string) => void
  onRequestFailed: () => void
  // send back the search object to the parent in case anything wants to be done with it
  onResponseRecieved?: (response: CardListResponse, query: ScryfallSearchMeta) => void
  className?: string
  containerClassName?: string
  searchString?: string
  // if we already have results, don't fetch on mount regardless of default search
  // the animation/ tab switching
  noAutoSearch?: boolean
  mainInputId?: string
  controlled?: boolean
  controlledTotal?: number

  hideTotal?: boolean
  hideInfoLink?: boolean

  placeholder?: string
  submitText?: string
}

const ScryfallSearchForm = ({
  onSubmitted,
  onRequestFailed,
  onResponseRecieved,
  searchString,
  className = '',
  containerClassName = '',
  noAutoSearch = false,
  mainInputId,
  controlled,
  controlledTotal,
  hideTotal = false,
  hideInfoLink = false,
  placeholder,
  submitText,
}: Props) => {
  let ref = useRef<any>(null)

  const isMobile = useAppSelector(state => state.active.isMobile)

  useEffect(() => {
    if (noAutoSearch) return
    if (searchString) return handleSearch()
    if (ref !== null && !isMobile) ref?.current.focus()
  }, [])

  const [search, setSearch] = useState<string>(searchString || '')
  const [total, setTotal] = useState<null | number>(null)

  const handleSearch = (e?: React.SyntheticEvent) => {
    e?.preventDefault()

    onSubmitted(search) // let the parent know we're about to submit the search
    if (controlled) return

    CardService.scryfallSearch(search, '', true, 1, true)
      .then(res => {
        setTotal(res.total)
        onResponseRecieved && onResponseRecieved(res, { query: search })
      })
      .catch(err => {
        console.error(err)

        onRequestFailed()
      })
  }

  useEffect(() => {
    if (searchString) setSearch(searchString)
  }, [searchString])

  return (
    <div className={`${styles.container} ${getStickyDeckSearch() ? styles.stickyForm : ''} ${containerClassName}`}>
      <form onSubmit={e => handleSearch(e)} className={`${styles.form} ${className}`}>
        <Input
          input={{ id: mainInputId, ref: (semanticRef: any) => (ref.current = semanticRef) }}
          value={search}
          className={styles.input}
          onChange={setSearch}
          onFocus={() => ref.current.select()}
          placeholder={placeholder || 'color:red cmc:1'}
        />
        <PhatButton type="submit" color="green">
          <Icon name="search" />
          {submitText}
        </PhatButton>
      </form>
      <div className={styles.row}>
        {!hideTotal && (
          <span className={styles.info}>
            {(total !== null || controlledTotal) && `Total Results: ${controlledTotal || total}`}
          </span>
        )}
        {!hideInfoLink && (
          <a href="https://scryfall.com/docs/syntax" target="_blank" rel="noopener noreferrer">
            <Icon style={{ fontSize: '1.25rem' }} name="info circle" />
            Syntax guide
          </a>
        )}
      </div>
    </div>
  )
}

export default ScryfallSearchForm

import React, { useEffect, useState } from 'react'
import { ResponsiveContainer, BarChart, CartesianGrid, YAxis, XAxis, Bar, Tooltip } from 'recharts'
import { Icon, Dropdown } from 'semantic-ui-react'

import { useAppSelector } from 'redux/hooks'

import { useFrozenValue, useVisibility } from 'utils/useVisibility'

import { calculateChartHeight, SUBTYPE_WHITELIST } from '../charts.const'

import styles from '../chart.module.scss'

type Props = { size?: number; autoWidth?: boolean }

type Data = { name: string; value: number }
type DropdownItem = { text: string; key: string; value: string }

const SubTypeDistribution = ({ size = 275, autoWidth = false }: Props) => {
  const [autoHeight, setAutoHeight] = useState(false)

  const cardMap = useAppSelector(state => state.deck.cardMap)
  const categories = useAppSelector(state => state.deck.categories)
  const [isVisible, elementRef] = useVisibility()

  const types = Object.values(cardMap).reduce((acc, card) => {
    acc.push(...card.types)

    // Doesn't matter about duplicates here since we're putting them into a set anyway
    if (card.front && card.back) {
      acc.push(...card.front.types)
      acc.push(...card.back.types)
    }

    return [...new Set(acc)]
  }, new Array<string>())

  const options: DropdownItem[] = types
    .map(t => ({ text: t, value: t, key: t }))
    .sort((a, b) => (a.value > b.value ? 1 : -1))

  const getDefaultSelectedType = () => {
    if (types.includes('Creature')) return 'Creature'
    if (types.includes('Land')) return 'Land'

    // Else just take whatever's first
    return options[0]?.value
  }

  const [selectedType, setSelectedType] = useState<string | undefined>(getDefaultSelectedType())

  useEffect(() => {
    if (selectedType) return // if we already have a type selected, don't change it

    setSelectedType(getDefaultSelectedType())
  }, [cardMap])

  const getTypeCounts = () =>
    Object.values(cardMap).reduce((acc, card) => {
      const primaryCategory = categories[card.categories[0]]

      // Not included, ignore them
      if (!primaryCategory.includedInDeck || primaryCategory.name === 'Sideboard') return acc

      const subTypes = new Array<string>()

      // We have no selected type because there are no cards in the deck (therefor can't default)
      if (!selectedType) return acc

      // If the card in question isn't of the selected type
      if (
        !card.types.includes(selectedType) &&
        !card.front?.types.includes(selectedType) &&
        !card.back?.types.includes(selectedType)
      )
        return acc

      // Get all the card types that I don't have selected
      const notMyType = Object.keys(SUBTYPE_WHITELIST).filter(t => t !== selectedType)

      // A list of all the subtypes that if I see, I know to ignore
      const blacklistedTypes = notMyType.reduce((acc, type) => {
        acc.push(...SUBTYPE_WHITELIST[type])

        return acc
      }, new Array<string>())

      const whiteListedSubTypes = SUBTYPE_WHITELIST[selectedType] || []

      // If we have a transform card, only use the types from that
      // the way the card is serialized, it'll always have a card.subType regardless if it's a transform card
      // So if we add card.subTypes && card.front.subTypes, we'll get duplicates
      if (card.front && card.back) {
        if (card.front.types.includes(selectedType)) subTypes.push(...card.front.subTypes)
        if (card.back.types.includes(selectedType)) subTypes.push(...card.back.subTypes)
      } else if (card.types.includes(selectedType)) subTypes.push(...card.subTypes)

      const filteredTypes = subTypes.filter(subType => {
        // If our subtype is whitelisted we're good to go
        if (whiteListedSubTypes.includes(subType)) return true

        // Creatures, Planeswalkers, and Tribal cards have no whitelist
        // So as long as the subtype isn't blacklisted, it's good
        if (whiteListedSubTypes.length === 0 && !blacklistedTypes.includes(subType)) return true

        // If we've gotten to this point, our
        return false
      })

      if (!filteredTypes.length) filteredTypes.push(' No subtype')

      for (const subtype of filteredTypes) {
        if (acc[subtype]) acc[subtype] += 1 * card.qty
        else acc[subtype] = 1 * card.qty
      }

      return acc
    }, {} as Record<string, number>)

  const data: Data[] | undefined = useFrozenValue(
    () => {
      const typeCounts = getTypeCounts()
      return Object.keys(typeCounts)
        .map(type => ({ name: type, value: typeCounts[type] }))
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    [cardMap, categories, selectedType],
    isVisible,
  )

  if (!data) return null

  const [height, disableAutoheight] = calculateChartHeight(size, autoHeight, data)

  // Recharts is fucking stupid and warns you every time it rerenders with a px height in a responseive container
  const chart = (
    <BarChart height={height} width={size} data={data} maxBarSize={20} layout="vertical">
      <CartesianGrid strokeDasharray="3" />
      <XAxis type="number" allowDecimals={false} />
      <YAxis type="category" dataKey="name" allowDecimals={false} />
      <Bar dataKey="value" fill="#C596D4" />
      <Tooltip contentStyle={{ color: 'black', fontWeight: 'bold' }} cursor={{ fill: '#8b8b8b55' }} />
    </BarChart>
  )

  return (
    <div ref={elementRef} className={styles.container} style={{ width: size }}>
      <div className={styles.header}>
        <h4>
          Sub Types{' '}
          <button
            title="Expand"
            onClick={() => setAutoHeight(!autoHeight)}
            className={styles.extendButton}
            disabled={disableAutoheight}>
            <Icon name="arrows alternate vertical" />
          </button>
        </h4>
        <Dropdown
          pointing="top right"
          placeholder="Select Type"
          options={options}
          value={selectedType}
          onChange={(_e: any, { value }: any) => setSelectedType(value)}
        />
      </div>
      {autoWidth ? (
        <ResponsiveContainer width={autoWidth ? '80%' : size} height={height}>
          {chart}
        </ResponsiveContainer>
      ) : (
        chart
      )}
    </div>
  )
}

export default SubTypeDistribution

import React, { useRef, useEffect } from 'react'
import { useDrop } from 'react-dnd'

import { DropItemTypes } from 'types/draggables'

type Props = {
  bottom?: boolean
}

const ScrollZone = ({ bottom }: Props) => {
  const scrollingInterval = useRef<any>(null)

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [DropItemTypes.CARD, DropItemTypes.FOLDER],
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [],
  )

  useEffect(() => {
    if (isOver) {
      scrollingInterval.current = setInterval(() => (document.documentElement.scrollTop += bottom ? 20 : -20), 25)
    } else clearInterval(scrollingInterval.current)

    return () => clearInterval(scrollingInterval.current)
  }, [isOver])

  return drop(
    <div
      style={{
        position: 'fixed',
        top: !bottom ? '0' : 'unset',
        bottom: bottom ? '0' : 'unset',
        display: canDrop ? 'flex' : 'none',
        backgroundColor: 'transparent',
        height: 100,
        width: '100%',
        zIndex: 4,
      }}
    />,
  )
}

export default ScrollZone

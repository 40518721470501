import React from 'react'
import { Table } from 'semantic-ui-react'

type Props = {
  name: string
  qty: number
  odds: number
}

const Row = ({ name, qty, odds }: Props) => (
  <Table.Row>
    <Table.Cell>{name}</Table.Cell>
    <Table.Cell>{qty}</Table.Cell>
    <Table.Cell>{odds}%</Table.Cell>
  </Table.Row>
)

export default Row

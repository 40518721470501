import React from 'react'

import ColorDistributionPie from 'components/deckPage/charts/ColorDistribution'
import CardTypeDistribution from 'components/deckPage/charts/CardTypes'
import ManaCurve from 'components/deckPage/charts/ManaCurve'
import SubTypeDistribution from 'components/deckPage/charts/SubTypes'
import CategoryDistribution from 'components/deckPage/charts/CategoryDistribution'

import styles from './charts.module.scss'

const Charts = () => (
  <div className={styles.container}>
    <ColorDistributionPie />
    <ManaCurve size={275} />
    <CategoryDistribution autoWidth />
    <CardTypeDistribution autoWidth />
    <SubTypeDistribution autoWidth />
  </div>
)

export default Charts

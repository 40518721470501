import React, { useEffect, useState, useCallback } from 'react'

/*
 * This hook returns a boolean isVisible and an elementRef. This ref will need to be passed to whatever element should be
 * watched for visibility.
 */
export const useVisibility = (offset = 0): [boolean, React.LegacyRef<HTMLDivElement>] => {
  const [isVisible, setIsVisible] = useState(false)

  const [ref, setRef] = useState<Element>()

  const elementRef = useCallback((node: Element) => node && setRef(node), [])

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting), {
      rootMargin: `${offset}px`,
    })

    if (ref) observer.observe(ref)

    return () => ref && observer.unobserve(ref)
  }, [ref])

  return [isVisible, elementRef as React.LegacyRef<HTMLDivElement>]
}

/**
 * This hook takes in a generator function that it runs on initialization and only again when called for by a dependency
 * change and if the shouldRun flag is true. The result of the generator is stored in local state and is what is
 * returned by the hook. The hook also stores previous dependency arrays in local state so it can check if the
 * dependencies have changed since it last has shouldRerun:true.
 */
export function useFrozenValue<T>(generator: () => T, dependencies: Array<any>, shouldRerun: boolean): T | undefined {
  const [value, setValue] = useState<T>()
  const [localDependencies, setLocalDependencies] = useState<any[]>()

  useEffect(() => {
    const dependenciesMatch = localDependencies && !localDependencies.filter((dep, i) => dep !== dependencies[i]).length
    if (!value || (shouldRerun && !dependenciesMatch)) {
      const newValue = generator()
      setValue(newValue)
      setLocalDependencies(dependencies)
    }
  }, [shouldRerun, ...dependencies])

  return value
}

import React from 'react'

import PrimaryButton from './primaryButton'
import CategoryDropdown from './categoryDropdown'
import RemoveButton from './removeButton'

import { CardType } from 'types/deck'

import styles from './categoryRow.module.scss'

type Props = {
  category: string
  primary: boolean
  card: CardType
  onCardChange: (updatedCard: CardType) => void
  onClose?: () => void
  removeDisabled: boolean
}

// Single row in the category list
const categoryRow = ({ category, primary, card, onCardChange, onClose, removeDisabled }: Props) => (
  <div className={`${styles.container} container`}>
    <div style={{ margin: '0 10px' }}>
      <PrimaryButton category={category} primary={primary} card={card} onCardChange={onCardChange} />
    </div>
    <div style={{ flex: '1 1 100px' }}>
      <CategoryDropdown onClose={onClose} category={category} card={card} onCardChange={onCardChange} />
    </div>
    <div style={{ margin: '0 10px' }}>
      <RemoveButton disabled={removeDisabled} category={category} card={card} onCardChange={onCardChange} />
    </div>
  </div>
)

export default categoryRow

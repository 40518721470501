import React, { useState } from 'react'
import { invert } from 'lodash'
import { Form, Input, Message, Dropdown, Loader, Dimmer } from 'semantic-ui-react'
import { useRouter } from 'next/router'

import { useAppSelector } from 'redux/hooks'

import DeckService from 'services/deck.service'

import { FormatType, HUMAN_FORMAT } from 'types/deck'
import { DeckRequest } from 'services/apiTypes/deck.types'

import FolderTree from 'components/misc/NewDeckModal/FolderTree'
import PhatButton from 'components/formElements/PhatButton'
import CheckboxFancybox from 'components/formElements/CheckboxFancybox'

import { humanReadableObjectToDropdown } from 'utils/DropdownConversions'
import { getNewDeckFormat, setNewDeckFormat } from 'services/accountSettings.service'
import { generateDeckUrl } from 'utils/deckSeo'

import styles from './newDeckForm.module.scss'

type Props = {
  copyName?: string
  copyId?: number
  copyFormat?: FormatType
  openInNewTab?: boolean
  createDeckWithCards?: boolean // If true, the deck will be created with cards that are in memory (sandbox, import builder)
  onCreate?: () => void // callback that can be called once the deck has been fully created
}

/**
 * Creates a new deck in one of three ways
 *
 *  1) Creates a new empty deck
 *  2) Copies an existing deck (using the apis contents of the deck)
 *  3) Creates a new deck given the in memory state of state.deck (think sandbox)
 */
const NewDeck = ({ copyName, createDeckWithCards, copyId, copyFormat, openInNewTab, onCreate }: Props) => {
  const router = useRouter()

  const cardMap = useAppSelector(state => state.deck.cardMap)
  const snapshotMeta = useAppSelector(state => state.deck.snapshotMeta)
  const cardPackage = useAppSelector(state => state.deck.cardPackage)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)

  let defualtCopyName = !copyName ? '' : `Copy of -`
  if (copyName && snapshotMeta?.parentDeckName) defualtCopyName += ` ${snapshotMeta.parentDeckName} -`
  if (copyName) defualtCopyName += ` ${copyName}`

  const [name, setName] = useState(defualtCopyName)
  const [privateDeck, setPrivateDeck] = useState(false)
  const [unlistedDeck, setUnlistedDeck] = useState(false)
  const [theorycraftedDeck, setTheorycraftedDeck] = useState(false)
  const [deckFormat, setDeckFormat] = useState<FormatType | undefined>(copyFormat || getNewDeckFormat())
  const [parentFolder, setParentFolder] = useState<number | null>(null)

  const resourceType = !cardPackage ? 'Deck' : 'Package'

  const togglePrivate = () => setPrivateDeck(!privateDeck)
  const toggleUnlisted = () => setUnlistedDeck(!unlistedDeck)

  const handleCreateDeck = async () => {
    setLoading(true)

    const body: Partial<DeckRequest> = {
      name,
      deckFormat: deckFormat || null,
      parentFolder,
      copyId,
      private: privateDeck,
      unlisted: unlistedDeck,
      theorycrafted: theorycraftedDeck,
      extras: {},
    }

    if (snapshotMeta) delete body.copyId // We'll add the cards back once the deck has been created

    try {
      const { id } = await DeckService.create(body)

      if (deckFormat) setNewDeckFormat(deckFormat) // cache last set format
      if (createDeckWithCards || !!snapshotMeta) await DeckService.add(id, Object.values(cardMap))
      if (onCreate) onCreate()

      const url = generateDeckUrl(id, name)

      if (openInNewTab) window.open(url, '_blank')?.focus()
      else router.push(url)
    } catch (err) {
      console.error(err)

      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form error={!!error} onSubmit={handleCreateDeck}>
      <Form.Field>
        <label>Deck Name:</label>
        <Input required value={name} placeholder="Deck Name" onChange={(_e, { value }) => setName(value)} />
      </Form.Field>
      {!cardPackage && (
        <>
          <Form.Field>
            <label>Deck Format:</label>
            {copyFormat ? (
              <p>{invert(HUMAN_FORMAT)[copyFormat]}</p>
            ) : (
              <Dropdown
                required
                placeholder="Select Format"
                name="formatDrop"
                // @ts-ignore - Semantic's form options are shit and don't allow for generic typing of their options
                onChange={(_e, { value }: { value: FormatType }) => setDeckFormat(value)}
                fluid
                search
                selection
                value={deckFormat}
                options={humanReadableObjectToDropdown(HUMAN_FORMAT)}
              />
            )}
          </Form.Field>
        </>
      )}
      <Form.Field>
        <label>Folder:</label>
        <FolderTree setFolderId={(id: number) => setParentFolder(id)} />
      </Form.Field>

      <div className={styles.flags}>
        <CheckboxFancybox
          checked={privateDeck}
          onClick={togglePrivate}
          header="Private"
          description={`The ${resourceType} is only visible to you`}
        />

        <CheckboxFancybox
          checked={unlistedDeck}
          onClick={toggleUnlisted}
          header="Unlisted"
          description={`The ${resourceType} is viewable with a direct link, but not shown in lists like search, folders, or your user page (for other users)`}
        />

        <CheckboxFancybox
          checked={theorycraftedDeck}
          header="Theorycrafted"
          onClick={() => setTheorycraftedDeck(!theorycraftedDeck)}
          description='The deck is not included by default in "In decks" view'
        />
      </div>

      <div className={styles.controls}>
        <PhatButton type="submit" color="green" disabled={loading}>
          {!loading ? <>Create {resourceType}</> : <>Creating {resourceType}...</>}
        </PhatButton>
      </div>

      {error && (
        <Message
          error
          header={`${resourceType} creation failed`}
          content={error.message || `An error has occurred while creating your ${resourceType}`}
        />
      )}
    </Form>
  )
}

export default NewDeck

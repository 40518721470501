import React, { useEffect, useRef, useState } from 'react'

import { CardType, SaveStatus } from 'types/deck'
import { DOUBLE_FACED_LAYOUTS, isCardRotateable } from 'types/card'

import TabContainer from 'components/elements/TabButtons'
import BasicCard from 'components/card/BasicCard'
import Prices from 'components/card/controlsAndInfo/prices'
import Link from 'components/elements/Link'
import PhatButton from 'components/formElements/PhatButton'
import GlobalOverlayStack from '../GlobalOverlayStack'
import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import Icon from 'components/elements/Icon'

import { basicKeybindSkips } from 'components/deckPage/modals/KeybindsOverlay'
import { creatEdhrecCardName } from 'utils/edhrec.utils'

import styles from './cardDetailsOverlay.module.scss'
import SimpleSpinner from 'components/elements/SimpleSpinner'

type Tab = {
  label: React.ReactNode
  body: React.ReactNode

  id: string
  disabled?: boolean
}

export type NextPrevOptions = {
  label: React.ReactNode
  onClick: () => void

  disabled?: boolean
}

type Props = {
  open: boolean
  onClose: () => void
  onTabChange: (tabId: any) => void
  card: CardType

  activeTabId: string
  tabs: Tab[]

  rightControls?: React.ReactNode

  nextPrevControls?: {
    previous: NextPrevOptions
    next: NextPrevOptions
  }

  children?: React.ReactNode

  className?: string

  onCardFlipped?: (flipped: boolean) => void
  onCardUpdated?: (card: CardType, removedCardIds?: number | number[]) => void

  lockFlippedAction?: boolean
  saving?: SaveStatus
}

const CardDetailsOverlay = ({
  open,
  onClose,
  onTabChange,
  card,
  tabs,
  activeTabId,
  nextPrevControls,
  rightControls: parentRightControls,
  className = '',
  children = null,
  onCardFlipped,
  onCardUpdated,
  lockFlippedAction = false,
  saving,
}: Props) => {
  const keylistener = useRef<(e: KeyboardEvent) => void>(() => null)

  useEffect(() => {
    window.removeEventListener('keydown', keylistener.current)

    if (!open || !nextPrevControls) return

    keylistener.current = (e: KeyboardEvent) => {
      if (basicKeybindSkips(e)) return

      if (e.key === 'ArrowLeft' && !nextPrevControls.previous.disabled) {
        nextPrevControls.previous.onClick()
      } else if (e.key === 'ArrowRight' && !nextPrevControls.next.disabled) {
        nextPrevControls.next.onClick()
      }
    }

    window.addEventListener('keydown', keylistener.current)

    return () => window.removeEventListener('keydown', keylistener.current)
  }, [open, nextPrevControls, card])

  const nextPrevButtonGroup = nextPrevControls ? (
    <>
      <button
        className={styles.nextPrevButton}
        onClick={nextPrevControls.previous.onClick}
        disabled={nextPrevControls.previous.disabled}>
        <Icon name="arrow left" /> <span>{nextPrevControls.previous.label}</span>
      </button>

      <button
        className={styles.nextPrevButton}
        onClick={nextPrevControls.next.onClick}
        disabled={nextPrevControls.next.disabled}>
        <span>{nextPrevControls.next.label}</span>
        <Icon name="arrow right" />
      </button>
    </>
  ) : null

  const forceHideArrows = nextPrevControls?.previous.disabled && nextPrevControls?.next.disabled

  const rightControls = (
    <div className={styles.defaultRightLinks}>
      <Link href={'/card?name=' + card?.name + '&uid=' + card?.uid} target="_blank">
        <PhatButton>
          <Icon name="external alternate" /> Card Page
        </PhatButton>
      </Link>

      <a href={`https://edhrec.com/cards/${creatEdhrecCardName(card)}`} target="_blank">
        <PhatButton>View on EDHREC</PhatButton>
      </a>
    </div>
  )

  return (
    <GlobalOverlayStack
      open={open}
      onClose={onClose}
      className={`${styles.container} ${className}`}
      mobileControls={!forceHideArrows && nextPrevButtonGroup} // If both buttons are disabled, don't show the button group
      mobileControlsClassName={styles.mobileNexPrevContainer}>
      <CardDetailsOverlayBodyOnly
        onTabChange={onTabChange}
        card={card}
        tabs={tabs}
        activeTabId={activeTabId}
        rightControls={parentRightControls !== undefined ? parentRightControls : rightControls}
        onCardFlipped={onCardFlipped}
        onCardUpdated={onCardUpdated}
        lockFlippedAction={lockFlippedAction}
        saving={saving}>
        {nextPrevControls && !forceHideArrows && <div className={styles.nextPrevContainer}>{nextPrevButtonGroup}</div>}
        {children}
      </CardDetailsOverlayBodyOnly>
    </GlobalOverlayStack>
  )
}

export default CardDetailsOverlay

type BodyOnlyProps = Pick<
  Props,
  | 'onTabChange'
  | 'tabs'
  | 'activeTabId'
  | 'rightControls'
  | 'children'
  | 'onCardFlipped'
  | 'onCardUpdated'
  | 'lockFlippedAction'
  | 'saving'
> & {
  card: CardType
}

// Does this need to be its own component anymore? I don't think so.
export const CardDetailsOverlayBodyOnly = (props: BodyOnlyProps) => {
  const [flipped, setFlipped] = useState(props.card.flippedDefault || false)
  const [rotated, setRotated] = useState(false)
  const [oneEightyRotate, setOneEightyRotate] = useState(false)

  useEffect(() => {
    setRotated(false)
    setOneEightyRotate(false)

    if (props.onCardFlipped) props.onCardFlipped(flipped)
  }, [flipped])

  useEffect(() => {
    // Under normal circumstances, when the card changes out I'd prefer a full rerender
    // However in the instance of the arrows in the card modal we want the image to already be loaded (prevents the card loader from showing)
    setFlipped(props.card.flippedDefault)
    setRotated(false)
    setOneEightyRotate(false)
  }, [props.card.id])

  const isFlipCard = DOUBLE_FACED_LAYOUTS.includes(props.card.layout)
  const isRotatable = isCardRotateable(props.card)
  const isOneEightyRotateCard = props.card.layout.includes('flip')

  const handleLockFlip = () => {
    if (!props.lockFlippedAction) return
    if (!props.onCardUpdated) return

    props.onCardUpdated({ ...props.card, flippedDefault: flipped })
  }

  const { createdAt, updatedAt } = props.card

  return (
    <div className={styles.content}>
      <div className={styles.titleRow}>
        <h3 className={`${styles.title} ${!props.card.name ? styles.defaultCard : ''}`}>{props.card.name || ' '}</h3>
        {(createdAt || updatedAt) && (
          <ArchidektDropdown
            menuClassName={styles.cardTimestampsMenu}
            options={[
              {
                type: 'message',
                label: `Added at: ${
                  createdAt
                    ? new Date(createdAt).toLocaleDateString() + ' - ' + new Date(createdAt).toLocaleTimeString()
                    : 'None'
                }`,
              },
              {
                type: 'message',
                label: `Updated at: ${
                  updatedAt
                    ? new Date(updatedAt).toLocaleDateString() + ' - ' + new Date(updatedAt).toLocaleTimeString()
                    : 'None'
                }`,
              },
            ]}>
            <button className={styles.cardTimestampsDropdown}>
              <Icon name="clock" />
            </button>
          </ArchidektDropdown>
        )}
      </div>

      <div className={styles.controls}>
        <TabContainer
          className={styles.tabContainer}
          optionsClassName={styles.tabButton}
          options={props.tabs.map(tab => ({
            label: tab.label,
            selected: tab.id === props.activeTabId,
            onClick: () => props.onTabChange(tab.id),
            disabled: tab.disabled,
            className: tab.id === props.activeTabId ? styles.activeTab : '',
          }))}
        />

        {!!props.rightControls && <div className={styles.rightControls}>{props.rightControls}</div>}
      </div>

      <div className={styles.splitLayout}>
        <div className={styles.left}>
          <div
            className={`
              ${styles.rotationContainer} 
              ${rotated ? styles.rotated : ''} 
              ${oneEightyRotate ? styles.oneEightyRotate : ''}
            `}>
            <BasicCard
              includeCorderOwned
              overrideCardResolutionPreference="large"
              card={props.card}
              flipped={flipped}
              className={styles.basicCard}
            />

            {props.saving === 'saving' && (
              <div className={styles.savingOverlay}>
                <SimpleSpinner size="small" className={styles.loader} />
              </div>
            )}
          </div>

          <Prices card={props.card} className={styles.row} />

          {/* Hiding this via CSS so the location of the arrow keys doesn't change when arrowing through cards */}
          <div className={`${styles.flipActionGroup} ${!isFlipCard ? styles.hideFlipCardActions : ''}`}>
            <button
              className={`${styles.flipButton} ${props.lockFlippedAction ? styles.flipLockEnabled : ''}`}
              onClick={() => setFlipped(!flipped)}
              color="violet">
              <Icon name="refresh" />
              Flip card
            </button>
            {props.lockFlippedAction && (
              <button
                onClick={handleLockFlip}
                className={styles.flipLockButton}
                disabled={props.card.flippedDefault === flipped}>
                <Icon name={props.card.flippedDefault !== flipped ? 'lock' : 'unlock'} />
              </button>
            )}
          </div>

          {isRotatable && (
            <button className={styles.flipButton} onClick={() => setRotated(!rotated)} color="violet">
              <Icon name="refresh" />
              Rotate card
            </button>
          )}

          {isOneEightyRotateCard && (
            <button className={styles.flipButton} onClick={() => setOneEightyRotate(!oneEightyRotate)} color="violet">
              <Icon name="refresh" />
              Flip card
            </button>
          )}
        </div>

        <div className={styles.right}>{props.tabs.find(tab => tab.id === props.activeTabId)?.body ?? null}</div>
      </div>

      {/* Useful for extras if needed in any particular view */}
      {!!props.children && <div className={styles.extraArea}>{props.children}</div>}
    </div>
  )
}

import React from 'react'
import { Icon, SemanticICONS } from 'semantic-ui-react'

import styles from './phatButton.module.scss'

const ColorClasses = {
  none: '',
  green: styles.green,
  orange: styles.orange,
  red: styles.red,
  blue: styles.blue,
}

type Props = {
  children?: React.ReactNode
  onClick?: (e: React.MouseEvent) => void
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  color?: keyof typeof ColorClasses
  style?: React.CSSProperties
  icon?: SemanticICONS
}

const PhatButton = ({
  children = null,
  onClick,
  className = '',
  color = 'none',
  disabled = false,
  type,
  style,
  icon,
}: Props) => (
  <button
    style={style}
    className={`${styles.button} ${ColorClasses[color]} ${className}`}
    onClick={onClick}
    disabled={disabled}
    type={type}>
    {icon && <Icon name={icon} />}
    {children}
  </button>
)

export default PhatButton

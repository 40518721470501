import React, { useEffect, useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'

import type { CardType } from 'types/deck'

import CategoryRow from './categoryRow'

import styles from './categories.module.scss'

type Props = {
  card: CardType
  onCardChange: (updatedCard: CardType) => void
}

// Category Selection list (the one where you can click the blue star)
const Categories = ({ card, onCardChange }: Props) => {
  const handleCategoryChange = (updatedCard: CardType) => onCardChange({ ...updatedCard, qty: updatedCard.qty || 1 })

  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    setIsAdding(false)
  }, [card.categories])

  return (
    <div className={styles.container}>
      {card.categories.map((category, i) => (
        <CategoryRow
          key={i}
          card={card}
          category={category}
          removeDisabled={i === 0 && card.categories.length < 2}
          primary={i === 0}
          onCardChange={handleCategoryChange}
        />
      ))}
      {isAdding && (
        <CategoryRow
          onClose={() => setIsAdding(false)}
          card={card}
          category=""
          primary={false}
          onCardChange={handleCategoryChange}
          removeDisabled={false}
        />
      )}
      <Button
        className={styles.addButton}
        onClick={() => setIsAdding(true)}
        icon
        compact
        labelPosition="left"
        color="blue">
        <Icon name="plus" /> Add Category
      </Button>
    </div>
  )
}

export default Categories

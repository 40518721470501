export type RegisterType = {
  username: string
  password: string
  birth_date: string
  email: string
  location: string
}

export const SVG_AVATARS = [
  'Colorless',
  'White',
  'Blue',
  'Black',
  'Red',
  'Green',
  'Azorius',
  'Orzhov',
  'Dimir',
  'Izzet',
  'Rakdos',
  'Golgari',
  'Boros',
  'Gruul',
  'Selesnya',
  'Simic',
]

export const JPG_AVATARS = [
  'Garcia Azorius',
  'Garcia Orzhov',
  'Garcia Dimir',
  'Garcia Izzet',
  'Garcia Rakdos',
  'Garcia Golgari',
  'Garcia Boros',
  'Garcia Gruul',
  'Garcia Selesnya',
  'Garcia Simic',
]

export const DEFAULT_AVATARS = [...SVG_AVATARS, ...JPG_AVATARS]

export const ROLES: Record<string, number> = {
  MOD: 1,
  TESTER: 2,
  CONTENT_CREATOR: 3,
  CH_WRITER: 4,
  EDHREC_WRITER: 5,
  COMMUNITY_FEED: 6,
  COMMANDER_SPELLBOOK_WRITER: 7,
  AD_FREE: 8,
  TCC_REFERRAL: 9,
  COMMAND_ZONE_REFERRAL: 10,
  COMMAND_ZONE_STAFF: 11,
  CONTEST_WINNER: 12,
  EDHREC_CAST_REFERRAL: 13,
  CARDSPHERE_WRITER: 14,
}

export const ROLE_ID_TO_NAME = Object.fromEntries(Object.entries(ROLES).map(a => a.reverse()))

export const PATREON_TIERS = {
  COMMON: 1,
  UNCOMMON: 2,
  RARE: 3,
  MYTHIC: 4,
}

export const COLLECTION_FLAG_OPTIONS = {
  'None': -1,
  'All': 0,
  'Paper': 1,
  'MTGO': 2,
  'Arena': 3,
}

import React from 'react'

import Icon from 'components/elements/Icon'

import { CardType } from 'types/deck'

import styles from './categoryRow.module.scss'

type Props = {
  primary: boolean
  category: string
  card: CardType

  onCardChange: (card: CardType) => void
}

const PrimaryButton = ({ primary, category, card, onCardChange }: Props) => {
  const handleMakePrimary = () => {
    if (!category) return

    const categories = card.categories.slice()

    categories.splice(categories.indexOf(category), 1)
    categories.unshift(category)

    onCardChange({ ...card, categories })
  }

  if (primary) return <Icon className={styles.icon} name="star" color="blue" />

  return <Icon className={styles.icon} onClick={handleMakePrimary} name="star outline" color="grey" />
}

export default PrimaryButton

import { get } from 'lodash'

import CookieService from 'services/cookie.service'
import LocalStorageService from 'services/localStorage.service'

import {
  SortOptionType,
  StackOptionType,
  ViewType,
  SORT,
  STACK,
  VIEW,
  LOCKING,
  HUMAN_GAME,
  FormatType,
  FORMAT,
} from 'types/deck'
import { PRICE_SOURCE } from 'types/active'

import { getMaxLockSize } from 'types/searchV2'
import { ControlledStackOptionsType, DEFAULT_CONTROLLED_STACK_OPTIONS } from 'types/controlledStacks'
import { ImageSize } from 'utils/ImageUrl'

// Cookie and LocalStorage keys
const sortKey = 'deckSorting'
const viewKey = 'deckView'
const stackKey = 'deckStack'
const priceKey = 'deckPrices' // This name is silly as the prices are used elsewhere
const deckLockKey = 'deckLocking'
const searchWidthKey = 'searchWidth'
const gameSearchValueKey = 'searchGame'
const searchUniqueKey = 'searchUnique'
const collectionPriceKey = 'collectionPrice'
const collectionGameKey = 'collectionGame'
const collectionFlagKey = 'collectionFlags'

const leftHandControls = 'left-hand-controls'
const stickyDeckSearch = 'sticky-deck-search'
const scryfallImagePreference = 'scryfall-image-preference'
const deckSearchOrderBy = 'deck-search-order-by'
const deckSearchAscending = 'deck-search-ascending'
const selectedTemplates = 'selected-cat-templates'
const rightCommander = 'right-commander'
const archidektSearchOrderby = 'archidekt-search-orderby'
export const newDeckFormat = 'tb-latest-format'
export const newDeckPrivate = 'tb-latest-private'
export const newDeckGameType = 'tb-latest-game-type'
const disableCollaborativeToasts = 'disable-collaborative-toasts'
const enableTouchDragDeckPage = 'enable-touch-drag-deck-page'
export const packageTabKey = 'tb-package-tab'
export const disableFoilLayer = 'tb-disable-foil-layer'

// prettier-ignore
const getSettingFromNextCookie = <T>(context: any = {}, key: string): T | undefined => get(context, `req.cookies[${key}]`) ?? get(context, `req.cookies[${key}]`) ?? get(context, `req.cookies.settings[${key}]`) ?? undefined
const getSettingsFromCookie = (key: string) => get(CookieService.get('settings'), key)

export const getSorting = (context?: any): SortOptionType =>
  Number(
    getSettingFromNextCookie<SortOptionType>(context, sortKey) ??
      CookieService.get(sortKey) ??
      get(CookieService.get('settings'), sortKey) ??
      SORT.ALPHA,
  ) as SortOptionType

export const getView = (context?: any): ViewType =>
  Number(
    getSettingFromNextCookie<ViewType>(context, viewKey) ??
      CookieService.get(viewKey) ??
      get(CookieService.get('settings'), viewKey) ??
      VIEW.CARD,
  ) as ViewType

export const getStack = (context?: any): StackOptionType =>
  Number(
    getSettingFromNextCookie<StackOptionType>(context, stackKey) ??
      CookieService.get(stackKey) ??
      get(CookieService.get('settings'), stackKey) ??
      STACK.CUSTOM,
  ) as StackOptionType

export const getControlledStackOptions = (deckId?: string | number): ControlledStackOptionsType => {
  let controlledStackOptions: ControlledStackOptionsType = (LocalStorageService.getObject('controlledStacks') || {})[
    `${deckId}`
  ]
  if (!controlledStackOptions) controlledStackOptions = { ...DEFAULT_CONTROLLED_STACK_OPTIONS }
  if (!controlledStackOptions.columnCount) controlledStackOptions.columnCount = 0
  if (!controlledStackOptions.columns) controlledStackOptions.columns = []
  if (!controlledStackOptions.controlled) controlledStackOptions.controlled = false
  if (!controlledStackOptions.showEmptyColumns) controlledStackOptions.showEmptyColumns = false

  return controlledStackOptions
}

export const getPriceSources = (priceSources?: Array<number>) => {
  if (priceSources) return priceSources

  const userSetPriceSources = CookieService.get(priceKey)
  const settingsPriceSource = get(CookieService.get('settings'), priceKey)

  if (userSetPriceSources) return userSetPriceSources
  if (settingsPriceSource) return settingsPriceSource

  return [PRICE_SOURCE.CK, PRICE_SOURCE.TCG]
}

export const getLeftPanelOpenDefault = (): boolean => {
  if (typeof window === 'undefined') return false
  if (!window.location.pathname.startsWith('/decks/')) return false

  const lockedValue = get(CookieService.get('settings'), deckLockKey)
  const viewPortWidth = window.innerWidth

  // Small screens ignore this
  if (viewPortWidth <= 1000) return false

  if (lockedValue === LOCKING.Left && viewPortWidth > 1000) return true
  if (lockedValue === LOCKING.Both && viewPortWidth > 1000) return true

  return false
}

export const getRightPanelOpenDefault = (): boolean => {
  if (typeof window === 'undefined') return false

  const lockedValue = get(CookieService.get('settings'), deckLockKey)
  const viewPortWidth = window.innerWidth

  // Small screens ignore this
  if (viewPortWidth <= 1000) return false

  if (lockedValue === LOCKING.Right && viewPortWidth > 1000) return true
  if (lockedValue === LOCKING.Both && viewPortWidth > 1400) return true

  return false
}

export const getDefaultLockWidth = (): number => {
  const lockedWidth = Number(get(CookieService.get('settings'), searchWidthKey) ?? 1)
  const maxWidth = getMaxLockSize()

  if (maxWidth < lockedWidth) return maxWidth
  if (lockedWidth) return lockedWidth

  return 1
}

export const getDefaultGameSearch = (): number => Number(getSettingsFromCookie(gameSearchValueKey) ?? HUMAN_GAME.Paper)

// Collection Stuffs
export const getDefaultAllEditionsSearch = (): boolean => getSettingsFromCookie(searchUniqueKey) ?? true
export const getCollectionPrice = (): number => Number(getSettingsFromCookie(collectionPriceKey) ?? PRICE_SOURCE.CK)
export const getCollectionGame = (): number => Number(getSettingsFromCookie(collectionGameKey) ?? HUMAN_GAME.Paper)
export const getCollectionFlags = (): number => Number(getSettingsFromCookie(collectionFlagKey) ?? 1)

export const setLocalSorting = (sort: SortOptionType) => CookieService.set(sortKey, sort)
export const setLocalView = (view: ViewType) => CookieService.set(viewKey, view)
export const setLocalStack = (stack: StackOptionType) => CookieService.set(stackKey, stack)
export const setLocalPriceSources = (priceSources: Array<number>) => CookieService.set(priceKey, priceSources)

export const getLeftHandControls = (): boolean => LocalStorageService.get(leftHandControls) === 'true'
export const setLeftHandControls = (value: boolean) => {
  LocalStorageService.set(leftHandControls, `${value}`)

  if (value) document.body.classList.add(leftHandControls)
  else document.body.classList.remove(leftHandControls)
}

export const setupAccountSettings = () => {
  if (getLeftHandControls()) document.body.classList.add(leftHandControls)
  else document.body.classList.remove(leftHandControls)
}

export const setStickyDeckSearch = (value: boolean) => LocalStorageService.set(stickyDeckSearch, `${value}`)
export const getStickyDeckSearch = (): boolean => LocalStorageService.get(stickyDeckSearch) === 'true'

export const setImageSizePreference = (size: ImageSize) => CookieService.set(scryfallImagePreference, size)

export const getImageSizePreference = (): ImageSize => {
  const value: any = CookieService.get(scryfallImagePreference) || 'normal'

  // Extra logic to be sure we're always returning clean values (since the cookie could be old or anything)
  if (value === 'small') return 'small'
  if (value === 'large') return 'large'

  return 'normal'
}

// prettier-ignore
export const getSelectedTemplates = (): Array<number | null> => (LocalStorageService.get(selectedTemplates) ?? '').split(',').map((value: string) => Number(value) ?? undefined)
export const setSelectedTemplates = (ids: number[]) => LocalStorageService.set(selectedTemplates, `${ids.join(',')}`)

export const setNewDeckFormat = (format: FormatType) => CookieService.set(newDeckFormat, `${format}`)
export const getNewDeckFormat = () => (Number(CookieService.get(newDeckFormat)) || FORMAT.EDH) as FormatType

export const setRightCommander = (value: boolean) => LocalStorageService.set(rightCommander, `${value}`)
export const getRightCommander = (): boolean => LocalStorageService.get(rightCommander) === 'true'

export const setDeckSearchOrderBy = (value: string) => LocalStorageService.set(deckSearchOrderBy, value)
export const getDeckSearchOrderBy = (): string | null => LocalStorageService.get(deckSearchOrderBy)

export const setDeckSearchAscending = (value: boolean) => LocalStorageService.set(deckSearchAscending, `${value}`)
export const getDeckSearchAscending = (): boolean => LocalStorageService.get(deckSearchAscending) === 'true'

export const getDeckSearchOrderingQueryParameter = () => {
  const orderBy = getDeckSearchOrderBy()

  if (!orderBy) return ''

  const ascending = getDeckSearchAscending()

  return `orderBy=${ascending ? '' : '-'}${orderBy}`
}

// prettier-ignore
export const setDisableCollaborativeToast = (disabled: boolean) => LocalStorageService.set(disableCollaborativeToasts, `${disabled}`)
export const getDisableCollaborativeToast = () => LocalStorageService.get(disableCollaborativeToasts) === 'true'

// prettier-ignore
export const setEnableTouchDragDeckPage = (enabled: boolean) => LocalStorageService.set(enableTouchDragDeckPage, `${enabled}`)
export const getEnableTouchDragDeckPage = () => LocalStorageService.get(enableTouchDragDeckPage) === 'true'

export const setFoilLayerIsDisabled = (disabled: boolean) => CookieService.set(disableFoilLayer, `${disabled}`)
export const getFoilLayerIsDisabled = () => CookieService.get(disableFoilLayer) === 'true'

import React, { useState } from 'react'
import { Button, Dropdown, Table } from 'semantic-ui-react'

import Row from './Row/Row'

import { ProbabilityContainer, Controls, Title } from 'components/deckPage/RightPanel/Probability/style'
import { useAppSelector } from 'redux/hooks'

/**
 * Math
 *
 * @param {number} r - number for factorial (AKA 5*4*3*2*1 || 5!)
 */
function factorial(r: number): number {
  let value = 1
  for (let i = 2; i <= r; i++) value *= i
  return value
}

/**
 * This is a math deal.
 *
 * Mathimatical formula for clairity: n!/((n-k)!k!)
 *
 * @param {number} n -
 * @param {number} k -
 */
function binomialCoefficient(n: number, k: number): number {
  const N = factorial(n)
  const K = factorial(k)

  return N / (factorial(n - k) * K)
}

/**
 * Hypergeometric Probability Distribution - It's math. for more info @see
 *
 * [Wikipiedia](https://en.wikipedia.org/wiki/Hypergeometric_distribution)
 *
 * @param {number} success - number of cards we'd consider a success
 * @param {number} drawnCards - total number of cards drawn
 * @param {number} deckSize - total size of the deck
 */
function hypergeometricProbabilityDistribution(success: number, drawnCards: number, deckSize: number): number {
  const failures = deckSize - success

  return (
    1 -
    (binomialCoefficient(success, 0) * binomialCoefficient(failures, drawnCards)) /
      binomialCoefficient(deckSize, drawnCards)
  )
}

const probabilitySelection = [
  { text: 'Cards', value: 'name' },
  { text: 'Types', value: 'typeCategory' },
  { text: 'CMC', value: 'cmc' },
  { text: 'Categories', value: 'categories' },
]

const Probability = () => {
  const cardMap = useAppSelector(state => state.deck.cardMap)
  const isMobile = useAppSelector(state => state.active.isMobile)
  const categories = useAppSelector(state => state.deck.categories)

  const [turnCount, setTurnCount] = useState(0)
  const [type, setType] = useState(probabilitySelection[0])

  const cardsDrawn = () => 7 + turnCount

  const nextTurn = () => setTurnCount(turnCount + 1)

  const previousTurn = () => {
    if (turnCount === 0) return

    setTurnCount(turnCount - 1)
  }

  const onDropdownChange = (_e: any, { value }: any) => {
    const selectedOption = probabilitySelection.find(p => p.value === value)

    if (!selectedOption) return

    setType(selectedOption)
  }

  const deck = Object.values(cardMap)
  const probabilities: Record<string, number> = {}

  let deckSize = 0

  for (const card of deck) {
    const primaryCategory = categories[card.categories[0]]

    if (primaryCategory.isPremier || !primaryCategory.includedInDeck || primaryCategory.name === 'Sideboard') continue

    // @ts-ignore
    // TODO - fix what's going on here
    const datum = type.value === 'categories' ? card[type.value] : [card[type.value]]

    deckSize += card.qty

    datum.forEach(data => {
      const currentCount = probabilities[data]

      if (currentCount) probabilities[data] = currentCount + card.qty
      else probabilities[data] = card.qty
    })
  }

  return (
    <ProbabilityContainer>
      <Controls>
        <Title>Turn: {turnCount}</Title>
        <Button.Group>
          <Button onClick={previousTurn}>Previous Turn</Button>
          <Button.Or />
          <Button onClick={nextTurn} positive>
            Next Turn
          </Button>
        </Button.Group>
      </Controls>
      <Controls>
        <Dropdown
          style={{ width: '100%' }}
          placeholder="Select Friend"
          onChange={onDropdownChange}
          selection
          options={probabilitySelection}
          value={type.value}
        />
      </Controls>
      <Table style={{ width: '100%' }} collapsing striped unstackable size={isMobile ? 'small' : undefined}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{type.text}</Table.HeaderCell>
            <Table.HeaderCell>Qty</Table.HeaderCell>
            <Table.HeaderCell>Odds</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.keys(probabilities).map(name => {
            const count = probabilities[name]
            const odds = Math.round(hypergeometricProbabilityDistribution(count, cardsDrawn(), deckSize) * 100)

            return <Row key={`Probability - ${name}`} name={name} qty={count} odds={odds} />
          })}
        </Table.Body>
      </Table>
    </ProbabilityContainer>
  )
}

export default Probability
